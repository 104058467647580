
$doc-upload-container-width:100%!default;
$doc-upload-container-border-with:1px!default;
$doc-upload-container-border-color:#b4cde7!default;
$doc-upload-container-border-style:dashed!default;
$doc-upload-bg-color:#f7f7f7!default;
$doc-upload-cta-icon:none!default;
$doc-upload-cta-color:#008CBA!default;
$doc-upload-cta-font-size:18px!default;


@-webkit-keyframes appear-from-inside
{
  from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
  75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
  to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
}
@keyframes appear-from-inside
{
  from	{ transform: translateY( -50% ) scale( 0 ); }
  75%		{ transform: translateY( -50% ) scale( 1.1 ); }
  to		{ transform: translateY( -50% ) scale( 1 ); }
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}
@mixin scale($scale) {
  @include transform(scale($scale));
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

.doc-up{
  width:100%;
  text-align: center;
  border:1px dashed #9bbdd3;
  border-radius: 3px;

  .doc-up-area{
    padding: 8px;
    height: 120px;
    background-color: #f7f7f7;
    position: relative;
    .doc-up-icon{
      padding: 10px 0 10px;
    }
    .doc-up-label{
      color: $primary-color;
      font-size: rem-calc(13);
    }
    .doc-up-input{
      /*width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;*/
      + label
      {
        color: #33487d;
        font-size: 16px;
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
      }
    }
    &.is-dragover{
      background-color: #f4f4f4;
    }
    .doc-up-message{
      font-family: $body-font-family;
      color: #8b8b8b;
      font-size: rem-calc(11);
      &.doc-up-error-message{
        //color:red;
      }
      &.browser-not-support-message:not( .doc-up-error-message ) {
        position: relative;
        font-size: 12px;
        top: -10px;
      }
    }
    &.doc-up-area-preview{
      .doc-up-icon{
        display: none;
      }
      .doc-up-label{
        display: none;
      }
      .doc-up-message{
        display: none;
        &.doc-up-error-message{
          display: inline;
        }
      }
      .doc-up-remove-file{
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .doc-up-thumbnail{
        img{
          height:100px;
        }
      }

      .doc-up-success-message{
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
  }
  &.doc-up-bg-inverse{
    .doc-up-area{
      background-color: #f4f4f4;
      &.is-dragover{
        background-color: #fff;
      }
    }
  }

  &.doc-up-has-error{
    border:2px dashed #ff0000;
    .doc-up-error-message{
      background-color: #fff6f6;
      color: #ff0000;
      border: 2px solid #ff0000;
      font-size: 14px;
      display: block;
      padding:10px;
      position: absolute;
      left:0;
      bottom: 150px;
      width:100%;
      box-shadow: 4px 6px 7px 0px rgba(89, 89, 89, 0.35);
      &:before{
        width: 24px;
        height: 11px;
        content: " ";
        background: url(/images/error-arrow.png) center;
        position: absolute;
        left: 50%;
        bottom: -11px;
        margin-left: -12px;
      }

    }
    .doc-up-remove-file{
      display: block!important;
      color: #ff0000;
    }
  }

}