$date-row-width: $row-width !default;
$date-column-gutter: $column-gutter !default;
$date-total-columns: $total-columns !default;

@mixin date-grid-row($behavior: false) {

	// use @include grid-row(nest); to include a nested row
	@if $behavior == nest {
		width: auto;
		margin-#{$default-float}: -($date-column-gutter/2);
		margin-#{$opposite-direction}: -($date-column-gutter/2);
		margin-top: 0;
		margin-bottom: 0;
		max-width: none;
	}

	// use @include grid-row(collapse); to collapsed a container row margins
	@else if $behavior == collapse {
		width: 100%;
		margin: 0;
		max-width: $date-row-width;
	}

	// use @include grid-row(nest-collapse); to collapse outer margins on a nested row
	@else if $behavior == nest-collapse {
		width: auto;
		margin: 0;
		max-width: none;
	}

		// use @include grid-row; to use a container row
	@else {
		width: 100%;
		margin-#{$default-float}: auto;
		margin-#{$opposite-direction}: auto;
		margin-top: 0;
		margin-bottom: 0;
		max-width: $date-row-width;
	}

	// Clearfix for all rows
	@include clearfix();
	overflow: visible;
}

@mixin date-grid-column(
$columns:false,
$last-column:false,
$center:false,
$offset:false,
$push:false,
$pull:false,
$collapse:false,
$float:true,
$position:false) {

	// If positioned for default .column, include relative position
	// push and pull require position set
	@if $position or $push or $pull {
		position: relative;
	}

	// If collapsed, get rid of gutter padding
	@if $collapse {
		padding-left: 0;
		padding-right: 0;
	}

		// Gutter padding whenever a column isn't set to collapse
		// (use $collapse:null to do nothing)
	@else if $collapse == false {
		padding-left: $date-column-gutter / 2;
		padding-right: $date-column-gutter / 2;
	}

	// If a column number is given, calculate width
	@if $columns {
		width: grid-calc($columns, $date-total-columns);

		// If last column, float naturally instead of to the right
		@if $last-column { float: $opposite-direction; }
	}

	// Source Ordering, adds left/right depending on which you use.
	@if $push { #{$default-float}: grid-calc($push, $date-total-columns); #{$opposite-direction}: auto; }
	@if $pull { #{$opposite-direction}: grid-calc($pull, $date-total-columns); #{$default-float}: auto; }

	@if $float {
		@if $float == left or $float == true { float: $default-float; }
		@else if $float == right { float: $opposite-direction; }
		@else { float: none; }
	}

	// If centered, get rid of float and add appropriate margins
	@if $center {
		margin-#{$default-float}: auto;
		margin-#{$opposite-direction}: auto;
		float: none !important;
	}

	// If offset, calculate appropriate margins
	@if $offset { margin-#{$default-float}: grid-calc($offset, $date-total-columns) !important; }

}

.date-row{
	@include date-grid-row();
	&.collapse {
		> .date-column,
		> .date-columns { @include date-grid-column($collapse:true, $float:false); }

		.date-row {margin-left:0; margin-right:0;}
	}

	.date-row { @include date-grid-row($behavior:nest);
		&.collapse { @include date-grid-row($behavior:nest-collapse); }
	}
	.date-column,.date-columns {
		@include date-grid-column();
	}
}

//orientation landscape
//(using aspect ratio to avoid issues appearing on orientation calculation when keyboard is open)
@media #{$small-only} and (orientation: landscape) {
	.small-landscape-1 {@include grid-column(1);}
	.small-landscape-2 {@include grid-column(2);}
	.small-landscape-3 {@include grid-column(3);}
	.small-landscape-4 {@include grid-column(4);}
	.small-landscape-5 {@include grid-column(5);}
	.small-landscape-6 {@include grid-column(6);}
	.small-landscape-7 {@include grid-column(7);}
	.small-landscape-8 {@include grid-column(8);}
	.small-landscape-9 {@include grid-column(9);}
	.small-landscape-10 {@include grid-column(10);}
	.small-landscape-11 {@include grid-column(11);}
	.small-landscape-12 {@include grid-column(12);}
}

//orientation portrait
@media #{$small-only} and (orientation: portrait) {
	.small-portrait-1 {@include grid-column(1);}
	.small-portrait-2 {@include grid-column(2);}
	.small-portrait-3 {@include grid-column(3);}
	.small-portrait-4 {@include grid-column(4);}
	.small-portrait-5 {@include grid-column(5);}
	.small-portrait-6 {@include grid-column(6);}
	.small-portrait-7 {@include grid-column(7);}
	.small-portrait-8 {@include grid-column(8);}
	.small-portrait-9 {@include grid-column(9);}
	.small-portrait-10 {@include grid-column(10);}
	.small-portrait-11 {@include grid-column(11);}
	.small-portrait-12 {@include grid-column(12);}
}

@media #{$medium-only} and (orientation: landscape) {
  .medium-landscape-1 {@include grid-column(1);}
  .medium-landscape-2 {@include grid-column(2);}
  .medium-landscape-3 {@include grid-column(3);}
  .medium-landscape-4 {@include grid-column(4);}
  .medium-landscape-5 {@include grid-column(5);}
  .medium-landscape-6 {@include grid-column(6);}
  .medium-landscape-7 {@include grid-column(7);}
  .medium-landscape-8 {@include grid-column(8);}
  .medium-landscape-9 {@include grid-column(9);}
  .medium-landscape-10 {@include grid-column(10);}
  .medium-landscape-11 {@include grid-column(11);}
  .medium-landscape-12 {@include grid-column(12);}
}

//orientation portrait
@media #{$medium-only} and (orientation: portrait) {
  .medium-portrait-1 {@include grid-column(1);}
  .medium-portrait-2 {@include grid-column(2);}
  .medium-portrait-3 {@include grid-column(3);}
  .medium-portrait-4 {@include grid-column(4);}
  .medium-portrait-5 {@include grid-column(5);}
  .medium-portrait-6 {@include grid-column(6);}
  .medium-portrait-7 {@include grid-column(7);}
  .medium-portrait-8 {@include grid-column(8);}
  .medium-portrait-9 {@include grid-column(9);}
  .medium-portrait-10 {@include grid-column(10);}
  .medium-portrait-11 {@include grid-column(11);}
  .medium-portrait-12 {@include grid-column(12);}
}