
$doc-upload-container-width:100%!default;
$doc-upload-container-border-with:1px!default;
$doc-upload-container-border-color:#b4cde7!default;
$doc-upload-container-border-style:dashed!default;
$doc-upload-bg-color:#fff!default;
$doc-upload-cta-icon:none!default;
$doc-upload-cta-color:#008CBA!default;
$doc-upload-cta-font-size:18px!default;


@-webkit-keyframes appear-from-inside
{
  from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
  75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
  to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
}
@keyframes appear-from-inside
{
  from	{ transform: translateY( -50% ) scale( 0 ); }
  75%		{ transform: translateY( -50% ) scale( 1.1 ); }
  to		{ transform: translateY( -50% ) scale( 1 ); }
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}
@mixin scale($scale) {
  @include transform(scale($scale));
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

.doc-up-container{
  width: 100%;
  text-align: center;
  margin: 0 auto;
  .doc-up{
    background-color: #fff;
    position: relative;
    padding: 20px 20px;
    height: 160px;
    label{
      outline: 0;
      a{
        font-weight: 500;
      }
    }
    &.has-advanced-upload{
      border:2px dashed #b4cde7;
      @include border-radius(5px);
      .doc-up-dragndrop{
        font-size: 14px;
        color: #a1a1a1;
        .doc-up-icon{
          display: none;
        }
      }
      .doc-up-icon{
        width: 100%;
        fill: #0096ce;
        display: block;
        margin-bottom: 20px;
      }
    }
    &.is-dragover{
      opacity: 0.8;
    }

    &.is-uploading .doc-up-input,
    &.is-success .doc-up-input,
    &.is-error .doc-up-input{
      visibility: hidden;
    }

    .doc-up-uploading,
    .doc-up-success,
    .doc-up-error{
      display: none;
    }
    &.is-uploading .doc-up-uploading,
    &.is-success .doc-up-success,
    &.is-error .doc-up-error{
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;

      -webkit-transform: translateY( -50% );
      transform: translateY( -50% );
    }
    .doc-up-uploading{
      font-style: italic;
    }
    .doc-up-success{
      -webkit-animation: appear-from-inside .25s ease-in-out;
      animation: appear-from-inside .25s ease-in-out;
      text-align: initial;
      img{
        display: block;
        height: 150px;
        padding: 5px 0 5px 0;
      }
      .doc-up-restart{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px;
        font-size: 13px;
      }
    }
  }
  .doc-up-uploading,
  .doc-up-success,
  .doc-up-error{
    display: none;
  }
  .doc-up-uploading{
    font-style: italic;
  }
  .doc-up-file{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    + label
    {
      font-size: 18px;
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
    }
  }
}