$headline-border-size   :1px!default;
$headline-border-color  :#e6e6e6!default;
$headline-font-size     :rem-calc(12)!default;
$headline-color         :#9bbdd3!default;

.label-title{
  border-bottom: $headline-border-size solid $headline-border-color;
  color:$headline-color;
  margin-bottom: rem-calc(14);
  font-size: $headline-font-size;
  letter-spacing: 0.5px;
  padding-bottom: rem-calc(2);
  .remove{
    font-size: rem-calc(12);
  }
  &.required{
    font-weight: normal;
    color: #d0021b;
  }
  &.optional{
    color: #686868;
  }
}