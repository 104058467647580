.top-bar{
  .left-small{
    i{
      font-size: 24px;
    }
    a,a:hover{
      color:gray;
    }
  }
  @media #{$small-only} {
    height: rem-calc(107);
  }
  .divider{
    &.light{
      border-top: solid 1px #d7d7d7;
    }
  }
  .toggle-topbar.menu-icon a {
    color: $topbar-menu-icon-color;
    i{
      height: 0;
      position: absolute;
      margin-top: -15px;
      top: 50%;
      right: 18px;
      display: none;
    }
  }
  &.expanded{
    .callout{
      display: none;
    }
    .top-bar-section{
      display: block;
    }
    .toggle-topbar.menu-icon a {
      span{
        display: none;
      }
      i{
        display: block;
      }
    }
  }
  .top-bar-section{
    display: none;
    li.title{
      @extend .callout-mobile;
      color: #989898;
      padding-left: $topbar-link-padding;
    }
    .email{
      padding-left: 10px;
      padding-bottom: .35rem + rem-calc(1);
      padding-top: .35rem + rem-calc(1);
      text-decoration: underline;
      color: #717171;
      font-size: 13px;
    }
    .button{
      font-weight: 300;
      font-size: 17px;
      padding-left: 15px;
      padding-right: 15px;
      font-family: "open-sans", sans-serif;
    }
    a{
      .fa-sign-out{
        vertical-align: middle;
      }
    }
  }
  .panel{
    position: relative;
    left: 13px;
    @media #{$small-only} {
      padding-right: 0!important;
      left: 8px;
    }
    &.callout{
      @media #{$small-only} {
        display: none;
      }
      background: #fdfdfd;
      border-color: #e3edf1;
      padding: 17px;
    }
    .help{
      position: absolute;
      width: 100%;
      top: -20px;
      left: 30px;
      font-size: 12px;
      color: #e27377;
      @media #{$small-only} {
        font-size: 9px;
        left: 20px;
        top: -18px;
      }
    }
    .phone-header{
      position: relative;
      top: 11px;
      right: 15px;
      color: #466f99!important;
      @media #{$small-only} {
        font-size: 11px;
        top: 6px;
      }
    }
    .triangle{
      position: absolute;
      bottom:-10px;
      left:rem-calc(-8.4);
      @include css-triangle(10px,#fdfdfd,right)
    }
    .triangle-wrapper{
      position: absolute;
      top:50%;
      left:0;
      margin-left: -22px;
      margin-top: -11px;
      @include css-triangle(11px,#e3edf1,right)
    }
  }
  .callout-mobile{
    background: #F7F7F7;
    font-size: rem-calc(13);
    text-align: center;
    line-height: 2.5;
    .help{
      color: #df5463;
    }
    .phone-header{
      margin-left: rem-calc(10);
      font-weight: 500!important;
      font-size: rem-calc(13);
      color: #466f99 !important;
    }
  }
  .row{
    max-width: $row-width;
  }
  .title-area{
    img{
      margin-left: 10px;
    }
  }
  .freedom-life-preserver:before{
    font-size: 32px;
    position: relative;
    top: 8px;
    left: 5px;
  }
  .dropdown{
    @include box-shadow(0px 0px 2px 2px rgba(29, 174, 239, 0.5));
    border: 1px solid #94cbe4;
    right: 23px!important;
    @include radius(5px);
    .triangle {
      position: absolute;
      background: url("/images/dropdown-arrow.png");
      width: 30px;
      height: 21px;
      right: 10px;
      z-index: 10;
      top: -21px;
      background-color: white;
    }
    li:nth-child(4){
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      a,label{
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }
    li:last-child{
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      a,label{
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    a,label{
      line-height: 40px!important;
      position: relative;
      i{
        font-size: 21px;
        position: relative;
        top: 3px;
      }
      i:nth-child(2){
        position: absolute;
        left: 80%;
        font-size: 14px;
      }
    }
    .accordion{
      width: 224px;
      .accordion-navigation a{
        font-size: 13px;
      }
      .content{
        padding:0 0 0 5px ;
        background-color: #ffffff;
        a{
          position: relative;
          border-radius: inherit;
          padding-left: 45px;
          display: block;
          i{
            left: 80%;
            position: absolute;
            display: block;
            font-size: 14px;
          }
        }
        a:hover{
          background-color: #edecec;
        }
        hr{
          margin: 0;
          color: #c3c1c1;
        }
      }
    }
  }

  .header-phone{
    line-height: 75px;
    margin-right: 90px;
  }
}