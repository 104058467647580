.embeddedServiceHelpButton
.helpButton
.uiButton {
  background-color: #005290!important;
  font-family: "Salesforce Sans", sans-serif!important;
}
.embeddedServiceHelpButton
.helpButton
.uiButton:focus {
  outline: 1px solid #005290!important;
}
@font-face {
  font-family: 'Salesforce Sans';
  src: url($package-path + '/fonts/salesforce/SalesforceSans-Regular.woff') format('woff')
}

.embeddedServiceSidebar {
  ul {
    font-size: inherit;
    line-height: inherit;
  }
  label{
    font-weight: normal;
    cursor: auto;
  }
  .label {
    background-color: inherit;
    font-size: inherit;
    display: inline-block;
    cursor: default;
    font-family: inherit;
  }
  select {
    margin: 0;
  }

  h2 {
    font-family: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
  }
}
.embeddedServiceSidebarHeader{
  button.headerItem {
    margin: 0;
  }
}
