$button-primary-gradient: false !default;
$button-primary-hover-gradient: $button-primary-gradient !default;
$button-primary-active-gradient: $button-primary-hover-gradient !default;

$button-primary-bg: $primary-color !default;
$button-primary-hover-bg: scale-color($button-primary-bg, $lightness: $button-function-factor) !default;
$button-primary-active-bg: $button-primary-hover-bg !default;

$button-primary-color: #fff !default;
$button-primary-hover-color: $button-primary-color !default;
$button-primary-active-color: $button-primary-hover-color !default;

$button-primary-box-shadow: false !default;
$button-primary-hover-box-shadow: false !default;
$button-primary-active-box-shadow: $button-primary-hover-box-shadow !default;

$button-primary-text-shadow: false !default;
$button-primary-hover-text-shadow: $button-primary-text-shadow !default;
$button-primary-active-text-shadow: $button-primary-hover-text-shadow !default;

$button-secondary-gradient: false !default;
$button-secondary-hover-gradient: $button-secondary-gradient !default;
$button-secondary-active-gradient: $button-secondary-hover-gradient !default;

$button-secondary-bg: $secondary-color !default;
$button-secondary-hover-bg: scale-color($button-secondary-bg, $lightness: $button-function-factor) !default;
$button-secondary-active-bg: $button-secondary-hover-bg !default;

$button-secondary-color: #fff !default;
$button-secondary-hover-color: $button-secondary-color !default;
$button-secondary-active-color: $button-secondary-hover-color !default;

$button-secondary-border: none !default;

$button-secondary-text-shadow: inherit !default;
$button-secondary-hover-text-shadow: $button-secondary-text-shadow !default;
$button-secondary-active-text-shadow: $button-secondary-hover-text-shadow !default;

$button-secondary-box-shadow: inherit !default;
$button-secondary-hover-box-shadow: $button-secondary-box-shadow !default;
$button-secondary-active-box-shadow: $button-secondary-hover-box-shadow !default;

$button-tertiary-gradient: false !default;
$button-tertiary-hover-gradient: $button-tertiary-gradient !default;
$button-tertiary-active-gradient: $button-tertiary-hover-gradient !default;

$button-tertiary-bg: $info-color !default;
$button-tertiary-hover-bg: scale-color($button-tertiary-bg, $lightness: $button-function-factor) !default;
$button-tertiary-active-bg: $button-tertiary-hover-bg !default;

$button-tertiary-color: #fff !default;
$button-tertiary-hover-color: $button-tertiary-color !default;
$button-tertiary-active-color: $button-tertiary-hover-color !default;

$button-tertiary-border: none !default;

$button-tertiary-text-shadow: inherit !default;
$button-tertiary-hover-text-shadow: $button-tertiary-text-shadow !default;
$button-tertiary-active-text-shadow: $button-tertiary-hover-text-shadow !default;

$button-tertiary-box-shadow: inherit !default;
$button-tertiary-hover-box-shadow: $button-tertiary-box-shadow !default;
$button-tertiary-active-box-shadow: $button-tertiary-hover-box-shadow !default;

$button-fourthly-gradient: false !default;
$button-fourthly-hover-gradient: $button-fourthly-gradient !default;
$button-fourthly-active-gradient: $button-fourthly-hover-gradient !default;

$button-fourthly-bg: #3582b8 !default;
$button-fourthly-hover-bg: scale-color($button-fourthly-bg, $lightness: $button-function-factor) !default;
$button-fourthly-active-bg: $button-fourthly-hover-bg !default;

$button-fourthly-color: #fff !default;
$button-fourthly-hover-color: $button-fourthly-color !default;
$button-fourthly-active-color: $button-fourthly-hover-color !default;

$button-fourthly-border: none !default;

$button-fourthly-text-shadow: inherit !default;
$button-fourthly-hover-text-shadow: $button-fourthly-text-shadow !default;
$button-fourthly-active-text-shadow: $button-fourthly-hover-text-shadow !default;

$button-fourthly-box-shadow: inherit !default;
$button-fourthly-hover-box-shadow: $button-fourthly-box-shadow !default;
$button-fourthly-active-box-shadow: $button-fourthly-hover-box-shadow !default;

$button-success-gradient: false !default;
$button-success-hover-gradient: $button-success-gradient !default;
$button-success-active-gradient: $button-success-hover-gradient !default;

$button-success-bg: $success-color !default;
$button-success-hover-bg: scale-color($button-success-bg, $lightness: $button-function-factor) !default;
$button-success-active-bg: $button-success-hover-bg !default;

$button-success-color: #fff !default;
$button-success-hover-color: $button-success-color !default;
$button-success-active-color: $button-success-hover-color !default;

$button-success-border: none !default;

$button-success-text-shadow: inherit !default;
$button-success-hover-text-shadow: $button-success-text-shadow !default;
$button-success-active-text-shadow: $button-success-hover-text-shadow !default;

$button-success-box-shadow: inherit !default;
$button-success-hover-box-shadow: $button-success-box-shadow !default;
$button-success-active-box-shadow: $button-success-hover-box-shadow !default;

$button-success-tiny-border: none !default;
$button-success-tiny-gradient-hover: $button-success-hover-gradient !default;
$button-success-tiny-gradient-active: $button-success-active-gradient !default;

$button-warning-gradient: false !default;
$button-warning-hover-gradient: $button-warning-gradient !default;
$button-warning-active-gradient: $button-warning-hover-gradient !default;

$button-warning-bg: $warning-color !default;
$button-warning-hover-bg: scale-color($button-warning-bg, $lightness: $button-function-factor) !default;
$button-warning-active-bg: $button-warning-hover-bg !default;

$button-warning-color: #fff !default;
$button-warning-hover-color: $button-warning-color !default;
$button-warning-active-color: $button-warning-hover-color !default;

$button-warning-border: none !default;

$button-warning-text-shadow: inherit !default;
$button-warning-hover-text-shadow: $button-warning-text-shadow !default;
$button-warning-active-text-shadow: $button-warning-hover-text-shadow !default;

$button-warning-box-shadow: inherit !default;
$button-warning-hover-box-shadow: $button-warning-box-shadow !default;
$button-warning-active-box-shadow: $button-warning-hover-box-shadow !default;

$button-ghost-gradient: false !default;
$button-ghost-hover-gradient: $button-warning-gradient !default;

$button-ghost-bg: transparent !default;
$button-ghost-hover-bg: $button-ghost-bg !default;
$button-ghost-color: #fff !default;

$button-ghost-gradient-advanced: false !default;
$button-ghost-border-white: 1px solid white !default;
$button-ghost-border-blue: 1px solid #6dabd5 !default;
$button-ghost-color-blue: #6dabd5 !default;

$button-huge-font-size: rem-calc(36) !default;

@mixin conditional-gradient($default, $hover, $active) {
	@if ($default) {
		@include background-image(linear-gradient($default));
	}

	@if ($hover) {
		&:hover {
			@include background-image(linear-gradient($hover));
		}
	}

	@if ($active) {
		&:active {
			@include background-image(linear-gradient($active));
		}
	}
}

@mixin conditional-bg($default, $hover, $active) {
	@if ($default) {
		background-color: $default;
	}

	@if ($hover) {
		&:hover {
			background-color: $hover;
		}
	}

	@if ($active) {
		&:active {
			background-color: $hover;
		}
	}
}

@mixin conditional-color($default, $hover, $active) {
	@if ($default) {
		color: $default;
	}

	@if ($hover) {
		&:hover {
			color: $hover;
		}
	}

	@if ($active) {
		&:active {
			color: $active;
		}
	}
}

@mixin conditional-box-shadow($default, $hover, $active) {
	@if ($default) {
		box-shadow: $default;
	}

	@if ($hover) {
		&:hover {
			box-shadow: $hover;
		}
	}

	@if ($hover) {
		&:active {
			box-shadow: $hover;
		}
	}
}

@mixin conditional-text-shadow($default, $hover, $active) {
	@if ($active) {
		text-shadow: $default;
	}

	@if ($active) {
		&:hover {
			text-shadow: $hover;
		}
	}

	@if ($active) {
		&:active {
			text-shadow: $active;
		}
	}
}

.button, button {
	outline: none;
	&.primary,&.disabled:hover {
		border-radius: 8px;
		@include conditional-bg($primary-achieve-blue, $primary-neutral-blue, $primary-achieve-blue);
		@include conditional-color($neutrals-white, $primary-achieve-blue, $neutrals-white);
		&.disabled:hover{
			background: $primary-achieve-blue;
		}
	}

	&.secondary {
		@include conditional-gradient($button-secondary-gradient, $button-secondary-hover-gradient, $button-secondary-active-gradient);
		@include conditional-bg($button-secondary-bg, $button-secondary-hover-bg, $button-secondary-active-bg);
		@include conditional-color($button-secondary-color, $button-secondary-hover-color, $button-secondary-active-color);
		@include conditional-box-shadow($button-secondary-box-shadow, $button-secondary-hover-box-shadow, $button-secondary-active-box-shadow);
		@include conditional-text-shadow($button-secondary-text-shadow, $button-secondary-hover-text-shadow, $button-secondary-active-text-shadow);
	}

	&.tertiary {
		border-radius: 8px;
		@include conditional-bg($extended-cobalt-cobalt-tint-04, $primary-neutral-blue, $extended-cobalt-cobalt-tint-04);
		@include conditional-color($primary-trust-dark-blue, $primary-achieve-blue, $primary-trust-dark-blue);
	}

	&.fourthly {
		@include conditional-gradient($button-fourthly-gradient, $button-fourthly-hover-gradient, $button-fourthly-active-gradient);
		@include conditional-bg($button-fourthly-bg, $button-fourthly-hover-bg, $button-fourthly-active-bg);
		@include conditional-color($button-fourthly-color, $button-fourthly-hover-color, $button-fourthly-active-color);
		@include conditional-box-shadow($button-fourthly-box-shadow, $button-fourthly-hover-box-shadow, $button-fourthly-active-box-shadow);
		@include conditional-text-shadow($button-fourthly-text-shadow, $button-fourthly-hover-text-shadow, $button-fourthly-active-text-shadow);
	}

	&.success {
		@include conditional-gradient($button-success-gradient, $button-success-hover-gradient, $button-success-active-gradient);
		@include conditional-bg($button-success-bg, $button-success-hover-bg, $button-success-active-bg);
		@include conditional-color($button-success-color, $button-success-hover-color, $button-success-active-color);
		@include conditional-box-shadow($button-success-box-shadow, $button-success-hover-box-shadow, $button-success-active-box-shadow);
		@include conditional-text-shadow($button-success-text-shadow, $button-success-hover-text-shadow, $button-success-active-text-shadow);
		&.tiny {
			@include box-shadow(none);
			border: $button-success-tiny-border;
			&:hover {
				@if ($button-success-tiny-gradient-hover) {
					@include background-image($button-success-tiny-gradient-hover);
				}
				border: $button-success-tiny-border;
			}
			&:active {
				@if ($button-success-tiny-gradient-active){
					@include background-image($button-success-tiny-gradient-active);
				}
				border: $button-success-tiny-border;
			}
		}
	}

	&.warning {
		@include conditional-gradient($button-warning-gradient, $button-warning-hover-gradient, $button-warning-active-gradient);
		@include conditional-bg($button-warning-bg, $button-warning-hover-bg, $button-warning-active-bg);
		@include conditional-color($button-warning-color, $button-warning-hover-color, $button-warning-active-color);
		@include conditional-box-shadow($button-warning-box-shadow, $button-warning-hover-box-shadow, $button-warning-active-box-shadow);
		@include conditional-text-shadow($button-warning-text-shadow, $button-warning-hover-text-shadow, $button-warning-active-text-shadow);
	}

	&.ghost {
		@include conditional-bg($button-ghost-bg, $button-ghost-hover-bg, false);
		&.white {
			border: $button-ghost-border-white;
			&:hover {
				color: #1c5b93;
				border: 1px solid #1c5b93;
			}
			&:active {
				color: #ffffff;
				border: 1px solid #1c5b93;
			}
		}
		&.blue {
			border: $button-ghost-border-blue;
			color: $button-ghost-color-blue;
			&:hover {
				color: #1c5b93;
				border: 1px solid #1c5b93;
			}
			&:active {
				color: #6dabd5;
				border: 1px solid #6dabd5;
			}
		}
		&.grey{
			border: solid 1px #9bbdd3!important;
			position: inherit;
			height: inherit;
			padding: 0.625rem 0 0.625rem;
			top: inherit;
			width: 100%;
		}
	}

	&.huge {
		font-size: $button-huge-font-size;
	}

	&.expand {
		@include button-size($padding: null, $full-width: true);
	}

	&.full{
		width: 100%;
	}
	&.full-tiny{
		font-size: rem-calc(12);
		padding: rem-calc(12 26 13);
		width: 100%;
	}

	> .first-line {
		font-size: rem-calc(26);
	}
	> .second-line {
		font-family: $body-font-family;
		font-size: rem-calc(16);
		line-height: rem-calc(18);
	}

	@media #{$medium-up} {
		> .second-line {
			font-size: rem-calc(14);
			line-height: rem-calc(18);
		}
	}

	i{
		font-size: 21px;
	}
	&.grey,&.grey:hover{
		color:#3582b8;
		border:1px solid #f2f2f2;
	}
	&.disabled-grey{
		background-color: #b7b7b7;
	}
}

