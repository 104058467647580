@mixin css3-prefix($property, $value) {
	-webkit-#{$property}: #{$value};
	-khtml-#{$property}: #{$value};
	-moz-#{$property}: #{$value};
	-ms-#{$property}: #{$value};
	-o-#{$property}: #{$value};
	#{$property}: #{$value};
}

@mixin box-shadow($shadow...) {
	@include css3-prefix('box-shadow',$shadow);
}



@mixin background-image($images...) {
	@include css3-prefix('background-image',$images);

}

@mixin text-shadow($shadow...) {
	@include css3-prefix('text-shadow',$shadow);
}

@mixin image-url($shadow...) {

}

@mixin font-url($shadow...) {

}