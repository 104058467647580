@import "~@consolplus-app/shared/sass/apply";

#finicity-wrapper {
  z-index: 500;
}

@media #{$medium-up} {
  #finicity-wrapper {
    position: fixed!important;
    left: 0;
    top: 0;
    width: 40%;
    height: 90%;
    margin-left: 30%;
    margin-right: 30%;
  }
}

#finicity-wrapper-div {
  opacity: 0.5;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

