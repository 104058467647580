@font-face {
  font-family: 'freedom';
  src: url($package-path + '/fonts/fontello/freedom.eot?25412056');
  src: url($package-path + '/fonts/fontello/freedom.eot?25412056#iefix') format('embedded-opentype'),
  url($package-path + '/fonts/fontello/freedom.woff?25412056') format('woff'),
  url($package-path + '/fonts/fontello/freedom.ttf?25412056') format('truetype'),
  url($package-path + '/fonts/fontello/freedom.svg?25412056#freedom') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*../../../
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'freedom';
    src: url('../font/freedom.svg?27373888#freedom') format('svg');
  }
}
*/
.freedom{
  font-family: "freedom";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

[class^="freedom-"]:before, [class*=" freedom-"]:before {
  @extend .freedom;
}

.freedom-alert-solid:before { content: '\21'; } /* '!' */
.freedom-chevron-down:before { content: '\22'; } /* '&quot;' */
.freedom-hashtag:before { content: '\23'; } /* '#' */
.freedom-dollar-sign:before { content: '\24'; } /* '$' */
.freedom-creditors:before { content: '\25'; } /* '%' */
.freedom-email:before { content: '\26'; } /* '&amp;' */
.freedom-finger:before { content: '\27'; } /* ''' */
.freedom-arrow-curve-down:before { content: '\28'; } /* '(' */
.freedom-thermometer:before { content: '\29'; } /* ')' */
.freedom-ribbon:before { content: '\2a'; } /* '*' */
.freedom-plus:before { content: '\2b'; } /* '+' */
.freedom-arrow-circle-right:before { content: '\2c'; } /* ',' */
.freedom-minus:before { content: '\2d'; } /* '-' */
.freedom-pencil:before { content: '\2e'; } /* '.' */
.freedom-trophy:before { content: '\2f'; } /* '/' */
.freedom-cog:before { content: '\30'; } /* '0' */
.freedom-banking:before { content: '\31'; } /* '1' */
.freedom-dollar-bill:before { content: '\32'; } /* '2' */
.freedom-employment:before { content: '\33'; } /* '3' */
.freedom-housing:before { content: '\34'; } /* '4' */
.freedom-loan:before { content: '\35'; } /* '5' */
.freedom-menu:before { content: '\36'; } /* '6' */
.freedom-phone:before { content: '\37'; } /* '7' */
.freedom-phone-solid:before { content: '\38'; } /* '8' */
.freedom-sign-out:before { content: '\39'; } /* '9' */
.freedom-freedomplus:before { content: '\3a'; } /* ':' */
.freedom-arrow-circle-left:before { content: '\3b'; } /* ';' */
.freedom-chevron-left-1:before { content: '\3c'; } /* '&lt;' */
.freedom-dashboard:before { content: '\3d'; } /* '=' */
.freedom-chevron-right:before { content: '\3e'; } /* '&gt;' */
.freedom-paperairplane:before { content: '\3f'; } /* '?' */
.freedom-ampersand:before { content: '\40'; } /* '@' */
.freedom-smiley:before { content: '\41'; } /* 'A' */
.freedom-heart:before { content: '\42'; } /* 'B' */
.freedom-clock:before { content: '\43'; } /* 'C' */
.freedom-gift:before { content: '\44'; } /* 'D' */
.freedom-chart:before { content: '\45'; } /* 'E' */
.freedom-signature:before { content: '\48'; } /* 'H' */
.freedom-judgement:before { content: '\4a'; } /* 'J' */
.freedom-lock-open:before { content: '\4c'; } /* 'L' */
.freedom-megaphone:before { content: '\4d'; } /* 'M' */
.freedom-piggybank:before { content: '\50'; } /* 'P' */
.freedom-safe:before { content: '\53'; } /* 'S' */
.freedom-check-circle:before { content: '\5c'; } /* '\' */
.freedom-account:before { content: '\61'; } /* 'a' */
.freedom-credit-card:before { content: '\63'; } /* 'c' */
.freedom-document:before { content: '\64'; } /* 'd' */
.freedom-ezekiel:before { content: '\65'; } /* 'e' */
.freedom-fingerprint:before { content: '\66'; } /* 'f' */
.freedom-headphones:before { content: '\68'; } /* 'h' */
.freedom-info:before { content: '\69'; } /* 'i' */
.freedom-jacqui:before { content: '\6a'; } /* 'j' */
.freedom-inkwell:before { content: '\6b'; } /* 'k' */
.freedom-lock:before { content: '\6c'; } /* 'l' */
.freedom-location:before { content: '\6d'; } /* 'm' */
.freedom-notification:before { content: '\6e'; } /* 'n' */
.freedom-life-preserver:before { content: '\70'; } /* 'p' */
.freedom-tools:before { content: '\74'; } /* 't' */
.freedom-check:before { content: '\76'; } /* 'v' */
.freedom-wrench:before { content: '\77'; } /* 'w' */
.freedom-close:before { content: '\78'; } /* 'x' */