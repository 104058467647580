.dms {
  width: 760px;
  margin: auto;
  padding: 0px;
  box-shadow: 0px 2px 3px -2px rgba(16, 24, 40, 0.03),
    0px 2px 9px -2px rgba(16, 24, 40, 0.09);

  @media #{$medium-only} {
    width: 100%;
  }
  @media #{$small-only} {
    width: 100%;
  }

  .doc-uploadrow {
    padding: 32px 24px;
  }
  .browse-section {
    padding: 8px 32px 40px 32px;
    margin: 0px;
  }
  .browse-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
  }
  p {
    color: #9bbdd3;
  }
  hr {
    border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  .btn {
    border: 2px solid #cbdde8 !important;
    padding: 16px 8px 25px 11px;
    border-radius: 5px;
  }
  .medium-offset {
    @media #{$medium-up} {
      margin-left: rem-calc(15);
    }
  }
  .folder-file-details-row{
    display: flex;
    flex-direction: row;
    gap: 15px;
    border-bottom: 1px solid $neutrals-greys-grey-4;
    padding: 16px;
    width: 540px;

    @media #{$medium-only} {
      width: 570px;
    }
    
    @media #{$small-only} {
      width: 100%;
    }
  }
  .folder-file-details{
    display: flex;
    flex-direction: column;
  }
  .meta-name {
    flex: 1;
    text-align: left;
    color: $neutrals-warm-black;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    @media #{$small-only} {
      font-size: 12px;
    }
    &.error {
      color: $error-color;
    }
  }
  .meta-icon {
    width: 20px;
  }
  .man-doc-buttons-box {
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    @media #{$small-only} {
      padding: 0px 17px;
      flex-direction: column;
    }
  }
  .man-doc-button{
    width: 243px;
    height: 48px;
    @media #{$small-only} {
      width: 100%;
      margin: 0;
    }
  }
  #save-and-continue-later {
    @media #{$small-only} {
      margin-bottom: 40px;
    }
  }
  .check-icon{
    @media #{$small-only} {
      display: none;;
    }
  }
  .folder-type {
    display: flex;
    font-size: 14px;
    font-weight: 300;
    color: $neutrals-black;
    text-align: left;
    letter-spacing: rem-calc(0.2);
    min-width: 120px;
    gap: 8px;
    img {
      margin-right: rem-calc(8);
    }

    @media #{$small-only} {
      width: calc(100% - 172px);
      min-height: 48px;
    }
  }
  .browse-items {
    width: 100%;
    .folder-data-items {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @media #{$small-only} {
        flex-direction: column;
      }

      .folder-details {
        width: calc(100% - 172px);
        display: flex;
        gap: 32px;
        @media #{$small-only} {
          flex-direction: column;
          width: 100%;
          gap: 16px;
        }
      }
    }
  }
  .folder-data-items {
    position: relative;
    margin: 20px auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media #{$small-only} {
      flex-direction: column;
    }

    .folder-details {
      width: calc(100% - 172px);
      display: flex;
      gap: 32px;
      @media #{$small-only} {
        flex-direction: column;
        width: 100%;
        gap: 16px;
      }
    }
  }
  .browse-btn,
  .browse-btn:hover {
    width: 140px !important;
    height: 48px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    border: 2px solid $primary-achieve-blue;
    border-radius: 8px;
    color: $primary-achieve-blue;
    font-size: 1rem !important;
    line-height: 1.5;
  }
  .description {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.3px;
    text-align: left;
    color: $neutrals-black;
  }
  .folder-sect-error {
    text-align: left;
  }
  .document-upload {
    color: $neutrals-black;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .upload-description {
    color: $neutrals-greys-grey-1;
  }
  .document-section {
    @media #{$medium-up} {
      display: flex;
      justify-content: flex-end;
    }
    @media #{$small-only} {
      padding-left: 0 !important;
    }
  }
  .progress {
    margin-top: 20px;
    height: 5px;
    border-radius: 3px;
    background-color: rgba(65, 168, 236, 0.3);
    .meter {
      height: 5px;
      border-radius: 2.5px;
      box-shadow: 0 0 4px 2px rgba(65, 168, 236, 0.4);
      background-color: #41a8ec;
      margin-top: -2px;
    }
  }
  .upload-msg {
    font-size: 10px;
    color: rgba(74, 74, 74, 0.5);
    font-weight: 300;
  }
  .btn-spinner {
    color: #b1cbdc;
    font-size: 20px !important;
    font-weight: normal;
    vertical-align: middle;
  }
}
.questions {
  .title {
    color: $neutrals-black;
  }
  .form-body {
    margin-top: 0;
    line-height: normal !important;
    letter-spacing: 0.4px;
    font-size: rem-calc(14);
  }
  .description {
    color: $neutrals-greys-grey-1;
  }
  .question {
    color: $neutrals-black;
  }
  .toggle-btn,
  .toggle-btn:hover,
  .toggle-btn:focus,
  .toggle-btn:active {
    font-size: rem-calc(14) !important;
    line-height: normal;
  }
  .back-btn {
    background-color: $extended-cobalt-cobalt-tint-04;
    color: $primary-trust-dark-blue;

    &:hover {
      background-color: $primary-neutral-blue;
      color: $primary-achieve-blue;
    }
  }
  .next-btn {
    background-color: $primary-achieve-blue;
    color: $neutrals-white;
    opacity: 1;

    &:active,
    &:hover {
      background-color: $primary-neutral-blue !important;
      color: $primary-achieve-blue !important;
    }
  }
  .form-footer {
    .button {
      min-width: 139px;
    }
    @media #{$small-only} {
      padding-left: rem-calc(18);
      padding-right: rem-calc(18);
    }
  }
  @media #{$small-only} {
    .under-line {
      border-bottom: 1px solid #e4e4e4;
    }
  }
}
.margin-top-minus-25 {
  margin-top: -25px;
}
.img {
  position: absolute;
  top: 28%;
  right: 10%;
}
.toggle-btn,
.toggle-btn:hover,
.toggle-btn:focus,
.toggle-btn:active {
  width: 100%;
  color: $primary-trust-dark-blue;
  background: $extended-cobalt-cobalt-tint-04;
  border-radius: 8px;
  line-height: 1.5;
  font-size: rem-calc(16) !important;
  padding-top: rem-calc(5);
  padding-bottom: rem-calc(5);
  &.checked {
    background: $extended-cobalt-cobalt-tint-02;
  }
}
