$range-slider-handle-bg-color: #446694 !default;

$include-range-slider-handle-border:false !default;
$range-slider-handle-border-width: rem-calc(1) !default;
$range-slider-handle-border-style: solid !default;
$range-slider-handle-border-color: none !default;


$include-range-slider-handle-outline:false !default;
$range-slider-handle-outline-width: rem-calc(1) !default;
$range-slider-handle-outline-color: none !default;
$range-slider-handle-outline-style: solid !default;

$include-range-slider-handle-shadow:true !default;
$range-slider-handle-shadow: 0px 0px 5px 2px rgba(29, 174, 239, .40) !default;

//Hover
$range-slider-handle-bg-hover-color: #446694 !default;

$include-range-slider-handle-border-hover:false !default;
$range-slider-handle-border-hover-width: rem-calc(1) !default;
$range-slider-handle-border-hover-style: solid !default;
$range-slider-handle-border-hover-color: none !default;

$include-range-slider-handle-outline-hover:false !default;
$range-slider-handle-outline-hover-width: rem-calc(1) !default;
$range-slider-handle-outline-hover-color: $range-slider-handle-bg-color !default;
$range-slider-handle-outline-hover-style: solid !default;

$include-range-slider-handle-shadow-hover:false !default;
$range-slider-handle-hover-shadow: 0px 0px 5px 2px rgba(29, 174, 239, .40) !default;

//Active
$range-slider-handle-bg-active-color: #446694 !default;

$include-range-slider-handle-border-active:false !default;
$range-slider-handle-border-active-width: rem-calc(10) !default;
$range-slider-handle-border-active-style: solid !default;
$range-slider-handle-border-active-color: $range-slider-handle-bg-color !default;

$include-range-slider-handle-outline-active:false !default;
$range-slider-handle-outline-active-width: rem-calc(1) !default;
$range-slider-handle-outline-active-color: #fff !default;
$range-slider-handle-outline-active-style: solid !default;

$include-range-slider-handle-shadow-active:false !default;
$range-slider-handle-active-shadow: 0px 0px 5px 2px rgba(29, 174, 239, .40) !default;

$range-slider-active-segment-bg:$range-slider-bar-bg-color !default;


$include-range-slider-icon : false !default;
$include-range-slider-icon-content : "|" " " "|" " " "|" !default;
$include-range-slider-icon-left:rem-calc(12)!default;
$include-range-slider-icon-top:rem-calc(13)!default;
$include-range-slider-icon-width:rem-calc(22)!default;
$include-range-slider-icon-font-size:rem-calc(18)!default;
$include-range-slider-icon-color:#d4d4d4 !default;
$include-range-slider-icon-color-hover:#89c8e5 !default;
$include-range-slider-icon-color-active:#d4d4d4 !default;
$include-range-slider-icon-letter-space:rem-calc(2)!default;


$range-slider-labels-color : #989898!default;
$range-slider-labels-font-size : rem-calc(14)!default;

$include-range-slider-value-tip:false!default;
$range-slider-value-tip-color : #6d787a!default;
$range-slider-value-tip-font-size : rem-calc(24)!default;
$range-slider-value-tip-width : rem-calc(100)!default;
$range-slider-value-tip-top : - rem-calc(30)!default;


@mixin range-slider-active-segment-style(
$bg: true,
$radius: false,
$round: false) {
	@if $bg == true { background: $range-slider-active-segment-bg; }
	@if $radius == true { @include radius($range-slider-radius); }
	@if $round == true { @include radius($range-slider-round); }
	display: inline-block;
	position: absolute;
	height: $range-slider-bar-height - rem-calc((strip-unit($range-slider-bar-border-width) * 2));

}

@mixin range-slider-labels-base{
	position:relative;
	top:($range-slider-bar-height + ($range-slider-handle-width - $range-slider-bar-height)/2);
	.min,.max{
		color:$range-slider-labels-color;
		font-size:$range-slider-labels-font-size;
	}
	.min{ @extend .left; }
	.max{ @extend .right; }
	@extend .clearfix;
}

@mixin range-slider-value-tip-base{
	position:relative;
	text-align: center;
	width:$range-slider-value-tip-width;
	left:50%;
	margin-left:- $range-slider-value-tip-width/2;
	top:$range-slider-value-tip-top;
	color:$range-slider-value-tip-color;
	font-size:$range-slider-value-tip-font-size;
}

@mixin range-slider-icon(){


	@if $include-range-slider-icon {
		@extend .fa;
		&:after{
			$top:$include-range-slider-icon-top;
			$left:$include-range-slider-icon-left;
			@if $include-range-slider-handle-border {
				$top: $include-range-slider-icon-top - $range-slider-handle-border-width;
				$left: $include-range-slider-icon-left - $range-slider-handle-border-width;

			}
			letter-spacing: $include-range-slider-icon-letter-space;
			content: $include-range-slider-icon-content;
			width:$include-range-slider-icon-width;
			top:$top;
			left:$left;
			font-size:$include-range-slider-icon-font-size;
			color:$include-range-slider-icon-color;
			position: absolute;
		}
		&:hover:after{
			$top:$include-range-slider-icon-top;
			$left:$include-range-slider-icon-left;
			@if $include-range-slider-handle-border-hover {
				$top: $include-range-slider-icon-top - $range-slider-handle-border-hover-width;
				$left: $include-range-slider-icon-left - $range-slider-handle-border-hover-width;

			}
			width:$include-range-slider-icon-width;
			top:$top;
			left:$left;
			color:$include-range-slider-icon-color-hover;
		}
		&.noUi-active:after{
			$top:$include-range-slider-icon-top;
			$left:$include-range-slider-icon-left;
			@if $include-range-slider-handle-border-active {
				$top: $include-range-slider-icon-top - $range-slider-handle-border-active-width;
				$left: $include-range-slider-icon-left - $range-slider-handle-border-active-width;

			}
			width:$include-range-slider-icon-width;
			top:$top;
			left:$left;
			color:$include-range-slider-icon-color-active;
		}
	}
}

@mixin range-slider-handle-custom-style($radius: false,$round: false) {
	background: $range-slider-handle-bg-color;
	@if $include-range-slider-value-tip {
		.slider-value{
			@include range-slider-value-tip-base;
		}
	}
	@if $include-range-slider-handle-border {
		border : $range-slider-handle-border-width $range-slider-handle-border-style $range-slider-handle-border-color;
		@if $include-range-slider-value-tip {
			.slider-value{
				top: $range-slider-value-tip-top - $range-slider-handle-border-width;
			}
		}
	}
	@if $include-range-slider-handle-outline {
		&:before{
			@if $radius {
				@include radius($range-slider-radius);
			}
			@if $round {
				@include radius($range-slider-round);
			}
			$right : 0;
			@if $include-range-slider-handle-border {
				$right : $range-slider-handle-border-width;
			}
			$bottom : 0;
			@if $include-range-slider-handle-border {
				$bottom : $range-slider-handle-border-width;
			}
			border : $range-slider-handle-outline-width $range-slider-handle-outline-style $range-slider-handle-outline-color ;
			position:absolute;
			left:-$right;
			top:-$bottom;
			width:$range-slider-handle-width;
			height: $range-slider-handle-height;
		}
	}
	@if $include-range-slider-handle-shadow {
		@include range-slider-active-segment-box-shadow($range-slider-handle-shadow);
	}

	&:hover {
		background: $range-slider-handle-bg-hover-color;
		@if $include-range-slider-handle-border-hover {
			border : $range-slider-handle-border-hover-width $range-slider-handle-border-hover-style $range-slider-handle-border-hover-color;
			@if $include-range-slider-value-tip {
				.slider-value{
					top: $range-slider-value-tip-top - $range-slider-handle-border-hover-width;
				}
			}
		}
		@if $include-range-slider-handle-outline-hover {
			&:before{
				@if $radius {
					@include radius($range-slider-radius);
				}
				@if $round {
					@include radius($range-slider-round);
				}
				$right : $range-slider-handle-width/2;
				@if $include-range-slider-handle-border-hover {
					$right : $range-slider-handle-border-hover-width;
				}
				$bottom : $range-slider-handle-height/2;
				@if $include-range-slider-handle-border-hover {
					$bottom : $range-slider-handle-border-hover-width;
				}
				border : $range-slider-handle-outline-hover-width $range-slider-handle-outline-hover-style $range-slider-handle-outline-hover-color ;
				position:absolute;
				left:-$right;
				top:-$bottom;
				width:$range-slider-handle-width;
				height: $range-slider-handle-height;
			}
		}
		@if $include-range-slider-handle-shadow-hover {
			@include range-slider-active-segment-box-shadow($range-slider-handle-hover-shadow);
		}

	}




	&.noUi-active{
		background: $range-slider-handle-bg-active-color;
		@if $include-range-slider-handle-border-active {
			border : $range-slider-handle-border-active-width $range-slider-handle-border-active-style $range-slider-handle-border-active-color;
			@if $include-range-slider-value-tip {
				.slider-value{
					top: $range-slider-value-tip-top - $range-slider-handle-border-active-width;
				}
			}
		}
		@if $include-range-slider-handle-outline-active {
			&:before{
				@if $radius {
					@include radius($range-slider-radius);
				}
				@if $round {
					@include radius($range-slider-round);
				}
				$right : $range-slider-handle-width/2;
				@if $include-range-slider-handle-border-active {
					$right : $range-slider-handle-border-active-width;
				}
				$bottom : $range-slider-handle-height/2;
				@if $include-range-slider-handle-border-active {
					$bottom : $range-slider-handle-border-active-width;
				}
				border : $range-slider-handle-outline-active-width $range-slider-handle-outline-active-style $range-slider-handle-outline-active-color ;
				position:absolute;
				left:-$right;
				top:-$bottom;
				width:$range-slider-handle-width;
				height: $range-slider-handle-height;
			}
		}
		@if $include-range-slider-handle-shadow-active{
			@include range-slider-active-segment-box-shadow($range-slider-handle-active-shadow);
		}
	}
	@include range-slider-icon();
}

@mixin range-slider-active-segment-box-shadow($box-shadow){
	-webkit-box-shadow: $box-shadow;
	-moz-box-shadow: $box-shadow;
	box-shadow: $box-shadow;
}

.noUi-target{
	@include range-slider-bar-base;
	@include range-slider-bar-style($bg:true, $radius:false);
  	//STATIC LABEL
  	>.slider-value{
	  position: absolute;
	  width: 100%;
	  color:$range-slider-value-tip-color;
	  font-size:$range-slider-value-tip-font-size;
	  text-align: center;
	  top: rem-calc(-50);
	}
	.noUi-base {
		@include range-slider-active-segment-style();
	}



	.noUi-handle {
		// clear no-ui styles
		border: none;
		border-radius: 0px;
		box-shadow: none;
		&:after,&:before{
			background: none;
		}
		@include range-slider-handle-base;
		@include range-slider-handle-style($bg:true, $radius: false);
		@include range-slider-handle-custom-style($radius: false);


	}
	.noUi-origin{
		@include range-slider-bar-style($bg:true, $radius:false);
		position: absolute;
	}
	&.radius {
		@include range-slider-bar-style($radius:true);
		.noUi-handle { @include range-slider-handle-style($radius: true); @include range-slider-handle-custom-style($radius: true); }
		.noUi-base { @include range-slider-active-segment-style($radius: true); }
		.noUi-origin{@include range-slider-bar-style($bg:true, $radius:true);}
	}
	&.round {
		@include range-slider-bar-style($round:true);
		.noUi-handle { @include range-slider-handle-style($round: true); @include range-slider-handle-custom-style($round: true); }
		.noUi-base { @include range-slider-active-segment-style($round: true); }
		.noUi-origin{@include range-slider-bar-style($bg:true, $round:true);}
	}
	margin-top: $form-spacing;
	@if $include-range-slider-value-tip {
		margin-top: $form-spacing - $range-slider-value-tip-top;
	}

	margin-bottom: $form-spacing + ($range-slider-bar-height + ($range-slider-handle-width - $range-slider-bar-height)/2);
	.labels{
		@include range-slider-labels-base;
	}
}

.error{
	.noUi-target {
		@if $include-range-slider-value-tip {
			margin-bottom: ($range-slider-bar-height + ($range-slider-handle-width - $range-slider-bar-height)/2);
		}
	}
}

.range-slider{

	.range-slider-handle{
		@include range-slider-handle-custom-style;
	}
	&.radius {
		.range-slider-active-segment { @include range-slider-active-segment-style($radius: true);}
		.range-slider-handle{
			@include range-slider-handle-custom-style($radius: true);
		}
	}
	&.round {
		.range-slider-active-segment { @include range-slider-active-segment-style($round: true); }
		.range-slider-handle{
			@include range-slider-handle-custom-style($round: true);
		}
	}
}