$progress-meter-position        : relative!default;
$progress-percent-font-size     : rem-calc(10)!default;
$progress-percent-color         : #ffffff!default;
$progress-percent-padding-right : 12px!default;
.progress {
  .meter {
    position: $progress-meter-position;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    .percent{
      padding-right: $progress-percent-padding-right;
      font-size:$progress-percent-font-size;
      color:$progress-percent-color;
      min-width: 20px;
      text-align: right;
    }
  }


}