$input-bg-gradient: false !default;
$input-focus-bg-gradient: false !default;
$input-font-weight: inherit !default;
$include-select-bg-gradient: false !default;
$select-bg-gradient: $input-bg-gradient !default;
$select-hover-bg-gradient: $input-bg-gradient !default;
$select-box-shadow: $input-box-shadow !default;
$select-arrow-width: 40px !default;
$select-arrow-border-width: $input-border-width !default;
$select-arrow-border-style: $input-border-style !default;
$select-arrow-border-color: $input-border-color !default;
$select-arrow-color: $input-font-color !default;

$checkbox-bg-color: $input-bg-color !default;
$checkbox-bg-gradient: $input-bg-gradient !default;
$checkbox-border-color: #94cbe4 !default;
$checkbox-border-style: $input-border-style !default;
$checkbox-border-width: $input-border-width !default;
$checkbox-border-radius: $global-radius !default;
$checkbox-box-shadow: $input-box-shadow !default;
$checkbox-icon-font-family: "fa" !default;
$checkbox-icon: "fa-check" !default;
$checkbox-icon-font-size: 14px !default;
$checkbox-width: 20px !default;
$checkbox-height: 20px !default;
$checkbox-margin-right: 5px !default;
$checkbox-color: $input-font-color !default;
$include-checkbox-hover-shadow: false !default;
$checkbox-hover-shadow: none !default;

$prefix-postfix-font-size: $form-label-font-size !default;

$checkbox-icon-offset-top: 2px !default;
$checkbox-icon-offset-left: 2px !default;

$radio-bg-color: $input-bg-color !default;
$radio-bg-gradient: $input-bg-gradient !default;
$radio-border-color: $input-border-color !default;
$radio-border-style: $input-border-style !default;
$radio-border-width: $input-border-width !default;
$radio-border-radius: $global-radius !default;
$radio-box-shadow: $input-box-shadow !default;
$radio-diameter: 20px !default;
$radio-circle-diameter: $radio-diameter * 0.6 !default;
$radio-margin-right: 5px !default;
$radio-color: $input-font-color !default;
$include-radio-hover-shadow: false !default;
$radio-hover-shadow: none !default;

$radio-icon-offset-top: 2px !default;
$radio-icon-offset-left: 3px !default;

$radio-bg-gradient: $input-bg-gradient !default;
$input-disabled-border-color: $input-border-color !default;
$input-disabled-color: $input-font-color !default;
$input-placeholder-font-color: #fff !default;
// $input-error-font-color: $alert-color !default;
// $input-error-border-color: $alert-color !default;
$input-error-include-glowing-effect: true !default;
// $glowing-effect-error-color: $alert-color !default;
$input-error-message-bg-color: $alert-color !default;
$input-error-message-line-height: 1 !default;
$input-error-message-bottom: $form-spacing !default;

$input-warning-font-color: $warning-color !default;
$input-warning-border-color: $warning-color !default;
$input-warning-include-glowing-effect: true !default;
$glowing-effect-warning-color: $warning-color !default;
$input-warning-message-bg-color: $input-error-message-bg-color !default;
$input-warning-message-line-height: $input-error-message-line-height !default;
$input-warning-message-bottom: $input-error-message-bottom !default;
$input-warning-message-font-color: $warning-color !default;
$input-warning-icon-color: $warning-color !default;

$input-error-icon: false !default;
$input-error-icon-offset-right: 0 !default;
$input-error-icon-offset-bottom: false !default;
$input-icon-font-family: "fa" !default;
$input-error-icon-class: "fa-exclamation-triangle" !default;
$input-success-icon-class: "fa-check" !default;
$input-success-font-color: $success-color !default;
$input-success-border-color: $success-color !default;
$input-success-icon: false !default;
$input-success-icon-offset: 0 !default;

$input-top-bottom-padding: $form-spacing/2 !default;
$input-left-right-padding: $form-spacing/2 !default;

@mixin form-select-gradient {
  @if ($include-select-bg-gradient) {
    @if ($select-bg-gradient) {
      @include background-image(linear-gradient($select-bg-gradient));
    }

    @if ($select-hover-bg-gradient) {
      &:hover {
        @include background-image(linear-gradient($select-hover-bg-gradient));
      }
    }
  } @else {
    background-image: none;
  }
}

@mixin center-helper {
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

@function calc-input-height() {
  @return ($input-font-size + ($input-top-bottom-padding * 3) - rem-calc(8));
}

// We use this mixin to create postfix/prefix form Labels
@mixin prefix-postfix {
  height: calc-input-height();
  line-height: calc-input-height();
  font-size: $prefix-postfix-font-size;
  font-size: $prefix-postfix-font-size;
}

@mixin custom-form-element-disabled {
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
  cursor: $input-disabled-cursor;
  color: $input-disabled-color;
}

// Regular Input Elements
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"] {
  @if ($input-bg-gradient) {
    @include background-image(linear-gradient($input-bg-gradient));
  }

  @if ($input-focus-bg-gradient) {
    &:focus {
      @include background-image(linear-gradient($input-focus-bg-gradient));
    }
  }
  padding: $input-top-bottom-padding $input-left-right-padding;

  height: calc-input-height();
  font-weight: $input-font-weight;
  &:disabled {
    @include custom-form-element-disabled();
  }
}
textarea {
  font-weight: $input-font-weight;
}

// Select Elements
select {
  @include form-select-gradient;
  box-shadow: $select-box-shadow;
  outline: none;

  @if $input-include-glowing-effect {
    //@include block-glowing-effect(hover, $glowing-effect-fade-time, $glowing-effect-color);
  }
}
.apply-to-me-text {
  color: $primary-achieve-blue;
}
.disclaimer-text {
  color: $neutrals-greys-grey-1;
  margin: 0px;
}
.privacy-links {
  text-decoration: underline;
  color: $primary-achieve-blue;
}
.privacy-links-margin-left {
  margin-left: rem-calc(5px);
}
.privacy-links-margin-right {
  margin-right: rem-calc(5px);
}
.mind-alimony-warning {
  color: $neutrals-greys-grey-1;
}
.select-element {
  margin: 0 0 $form-spacing 0;
  position: relative;
  &.error {
    margin-bottom: 0;
  }
  font-weight: $input-font-weight;
  &:hover {
    .selector {
      @if $input-include-glowing-effect {
        box-shadow: 0 0 5px $glowing-effect-color;
        border-color: $glowing-effect-color;
      }
    }
    .disabled {
      box-shadow: none;
    }
  }
  .selector {
    @include form-select;
    @include form-select-gradient;
    box-shadow: $select-box-shadow;
    padding: $input-top-bottom-padding $input-left-right-padding;
    height: calc-input-height();
    overflow: hidden;
    padding: 0;
    position: relative;

    .select-current-value {
      height: 100%;
      line-height: normal;
      margin-right: $select-arrow-width;
      overflow: hidden;
      padding: $input-top-bottom-padding $input-left-right-padding;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;

      @include center-helper;
    }

    .select-arrow {
      height: 100%;
      width: $select-arrow-width;
      border-left: $select-arrow-border-width $select-arrow-border-style
        $select-arrow-border-color;
      text-align: center;
      float: right;
      color: $select-arrow-color;
      @include center-helper;

      i {
        margin-left: -$input-border-width;
        vertical-align: middle;
      }
    }

    &.disabled {
      @include custom-form-element-disabled();
    }
    &.disabled:hover {
      box-shadow: none;
    }
  }

  select {
    background: none;
    border: 1px solid #fff;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    padding: $input-top-bottom-padding $input-left-right-padding;
    height: calc-input-height();
  }
}

label.checked {
  color: $primary-achieve-blue;
}

label.checker {
  margin: 0 0 $form-spacing 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover span {
    border-color: $primary-achieve-blue;
  }

  span {
    @extend .#{$checkbox-icon-font-family};
    @extend .radius;

    border-style: $checkbox-border-style;
    border-width: $checkbox-border-width;
    border-color: $neutrals-black;
    border-radius: 4px;
    height: $checkbox-height;
    margin: 0px 8px 0px 0px;
    position: relative;
    width: $checkbox-width;
    vertical-align: middle;
    pointer-events: none;

    @if ($checkbox-bg-gradient) {
      @include background-image(linear-gradient($checkbox-bg-gradient));
    } @else {
      background: $checkbox-bg-color;
    }

    &.checked {
      background-color: $primary-achieve-blue;
      &:before {
        font-size: $checkbox-icon-font-size;
        display: inline;
        position: absolute;
        top: $checkbox-icon-offset-top;
        left: $checkbox-icon-offset-left;
        color: $white;
        @extend .#{$checkbox-icon}:before;
      }
    }

    input[type="checkbox"] {
      display: none;
    }
  }

  @include center-helper;
}

label.radio {
  margin: 0 0 $form-spacing 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @if $include-radio-hover-shadow {
    &:hover span {
      -webkit-box-shadow: $radio-hover-shadow;
      -moz-box-shadow: $radio-hover-shadow;
      box-shadow: $radio-hover-shadow;
    }
  }

  span {
    @extend .#{$checkbox-icon-font-family};
    @extend .radius;

    border-style: $radio-border-style;
    border-width: $radio-border-width;
    border-color: $radio-border-color;
    border-radius: 50%;
    height: $radio-diameter;
    margin-right: $radio-margin-right;
    position: relative;
    text-align: center;
    width: $radio-diameter;
    vertical-align: middle;
    pointer-events: none;

    @if ($radio-bg-gradient) {
      @include background-image(linear-gradient($radio-bg-gradient));
    } @else {
      background: $radio-bg-color;
    }

    &.checked {
      &:before {
        content: "";
        background: $radio-color;
        border-radius: 50%;
        display: inline-block;
        height: $radio-circle-diameter;
        width: $radio-circle-diameter;
        position: absolute;
        left: (
            $radio-diameter - $radio-circle-diameter - ($radio-border-width * 2)
          ) / 2;
        top: (
            $radio-diameter - $radio-circle-diameter - ($radio-border-width * 2)
          ) / 2;
      }
    }

    input[type="radio"] {
      display: none;
    }
  }

  @include center-helper;
}

label {
  &.inline {
    padding: $input-top-bottom-padding + rem-calc($input-border-width) 0;
    width: 100%;
    @media #{$small-only} {
      margin: 0;
      padding: 0;
    }
  }
}

@mixin custom-form-error-message {
  //TODO: remove impportant
  color: $functional-error-error-red;
  background: $input-error-message-bg-color !important;
  line-height: $input-error-message-line-height;
  margin-bottom: $input-error-message-bottom !important;
}
@mixin custom-form-warning-message {
  background: $input-warning-message-bg-color !important;
  line-height: $input-warning-message-line-height;
  margin-bottom: $input-warning-message-bottom !important;
}
@mixin custom-input-error-style {
  color: $neutrals-black;
  border-color: $functional-error-error-red;
}

@mixin custom-input-warning-style {
  color: $input-warning-font-color;
  border-color: $input-warning-border-color;
}
@mixin custom-input-success-style {
  color: $input-success-font-color;
  border-color: $input-success-border-color;
}

::-webkit-input-placeholder {
  color: $neutrals-greys-grey-1;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $neutrals-greys-grey-1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $input-placeholder-font-color;
}

:-ms-input-placeholder {
  color: $input-placeholder-font-color !important;
}

input::-ms-clear {
  display: none;
}

input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
  display: none;
}

.prefix,
.postfix {
  @include prefix-postfix;
}
.disabled {
  .prefix,
  .postfix {
    @include custom-form-element-disabled();
  }
}
#{data('abide')} {
  i.icon {
    display: none;
  }
}

// error
.error {
  input,
	textarea,
	.selector>.select-arrow,//for custom select
	.selector,
	select {
    @include custom-input-error-style;
    @if $input-error-include-glowing-effect {
      @include block-glowing-effect(
        focus,
        $glowing-effect-fade-time,
        $glowing-effect-error-color
      );
    }
  }
  &.select-element:hover {
    .selector {
      @if $input-include-glowing-effect {
        box-shadow: 0 0 5px $glowing-effect-error-color;
        border-color: $glowing-effect-error-color;
      }
    }
  }
  small,
  span {
    @include custom-form-error-message;
  }
  @if $input-error-icon {
    &,
    label,
    div {
      position: relative;
      i.icon {
        display: block;
        position: absolute;
        color: $input-error-icon-color;
        @if $input-error-icon-offset-bottom !=false {
          bottom: $input-error-icon-offset-bottom;
        } @else {
          bottom: (calc-input-height() - $input-error-icon-font-size)/2;
        }
        right: $input-error-icon-offset-right;
        font-size: $input-error-icon-font-size;
        @extend .#{$input-icon-font-family};
        @extend .#{$input-error-icon-class};
      }
    }
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $neutrals-black;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $neutrals-black;
    opacity: 1;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $neutrals-black;
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $neutrals-black;
  }
}

small.error,
span.error {
  @include custom-form-error-message;
}
input.error,
textarea.error {
  @include custom-input-error-style;
}

.error .postfix,
.error .prefix {
  color: $neutrals-black;
  line-height: 3rem;
  @include custom-input-error-style;
  @if $input-error-include-glowing-effect {
    @include block-glowing-effect(
      focus,
      $glowing-effect-fade-time,
      $glowing-effect-error-color
    );
  }
}

.error small.columns.error {
  padding: $input-error-message-padding !important;
}

.warning {
  input,
  textarea,
  .selector > .select-arrow,
  .selector,
  select {
    @include custom-input-warning-style;
    margin-bottom: 0;
    @if $input-warning-include-glowing-effect {
      @include block-glowing-effect(
        focus,
        $glowing-effect-fade-time,
        $glowing-effect-warning-color
      );
    }
  }
  &.select-element {
    margin-bottom: 0;
  }
  &.select-element:hover {
    .selector {
      @if $input-include-glowing-effect {
        box-shadow: 0 0 5px $glowing-effect-warning-color;
        border-color: $glowing-effect-warning-color;
      }
    }
  }
  small.warning,
  span.warning {
    @include form-error-message;
    color: $input-warning-message-font-color;
    @include custom-form-error-message;
    padding: $input-error-message-padding !important;
  }
  @if $input-error-icon {
    &,
    label,
    div {
      position: relative;
      i.icon {
        display: block;
        position: absolute;
        color: $input-warning-icon-color;
        @if $input-error-icon-offset-bottom !=false {
          bottom: $input-error-icon-offset-bottom;
        } @else {
          bottom: (calc-input-height() - $input-error-icon-font-size)/2;
        }
        right: $input-error-icon-offset-right;
        font-size: $input-error-icon-font-size;
        @extend .#{$input-icon-font-family};
        @extend .#{$input-error-icon-class};
      }
    }
  }
  label {
    color: $input-warning-font-color;
  }
}
small.warning,
span.warning {
  display: none;
}

input.warning,
textarea.warning {
  @include custom-input-warning-style;
}

.warning .postfix,
.warning .prefix {
  color: $input-warning-font-color;
  line-height: 3.5rem;
  @include custom-input-warning-style;
  @if $input-warning-include-glowing-effect {
    @include block-glowing-effect(
      focus,
      $glowing-effect-fade-time,
      $glowing-effect-warning-color
    );
  }
}

// success
.success {
  @if $input-success-icon {
    label,
    div {
      position: relative;
      i.icon {
        display: block;
        position: absolute;
        color: $input-success-icon-color;
        bottom: (calc-input-height() - $input-success-icon-font-size)/2;
        right: $input-success-icon-offset;
        font-size: $input-success-icon-font-size;
        @extend .#{$input-icon-font-family};
        @extend .#{$input-success-icon-class};
      }
    }
  }
  input,
  textarea {
    @include custom-input-success-style;
  }
}

.form-header,
.form-body,
.form-footer {
  padding-right: rem-calc(40);
  padding-left: rem-calc(40);
  button,
  .button {
    &.small {
      min-width: 117px;
    }
  }
}
.form-body {
  @media #{$small-only} {
    padding-right: rem-calc(15);
    padding-left: rem-calc(15);
  }
}
.form-footer {
  border-top: solid #dddddd;
  border-width: 1px 0 0;
  padding-top: rem-calc(25);
  padding-bottom: rem-calc(25);
  padding-right: rem-calc(50);
  padding-left: rem-calc(50);
  button {
    margin: 0;
  }
}
.form-body {
  margin-top: rem-calc(15);
  margin-bottom: rem-calc(15);
}
.label-helper {
  line-height: 1;
  a {
    font-size: rem-calc(11);
  }
}
.heading-thank-you {
  color: $neutrals-black;
}
.heading-application {
  color: $neutrals-greys-grey-1;
}
.personal-info {
  color: $neutrals-black !important;
}
