$package-path: "~@consolplus-app/shared" !default;
//Project specific settings customizations
// import font awesome;
$fa-font-path: "../../fonts/awesome/";
@import "components/scrollbar";
//@import '../../../../node_modules/components-font-awesome/scss/font-awesome';
// import fontello
@import "fontello/freedom";
@import "fontello/animation";

@import "settings/apply-settings";

// import foundation
@import "foundation/normalize";
@import "foundation/foundation";

//Plugins project specific customizations (based on foundation)
@import "components/functions";
@import "components/range-slider";
@import "components/forms";
@import "components/buttons";
@import "components/grid";
@import "components/balloon";
@import "components/type";
@import "components/tabs";
@import "components/progress-bars";
@import "components/upload";
@import "components/docup";
@import "components/spinner";
@import "components/breadcrumbs";
@import "components/reveal";
@import "components/top-bar";
@import "components/password-confirmation";
@import "components/headline";
@import "components/utils";
@import "components/table";
@import "components/app";
@import "components/dms";
@import "components/ews";

$toggle-text-font-size: rem-calc(12) !default;

.footer-flex {
  display: flex;
  justify-content: space-between;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 14px !important;
    line-height: 19px;
  }

  @media #{$small-only} {
    flex-direction: column-reverse;
    ul {
      margin-top: 30px;
    }
  }
}
.apply {
  .flow-content {
    padding-left: rem-calc(24);
    padding-right: rem-calc(24);
    padding-bottom: rem-calc(50);
  }
  button[type="submit"] {
    width: rem-calc(279);
    margin-bottom: rem-calc(0);
    border-radius: rem-calc(8);
    background-color: $primary-achieve-blue;
    opacity: 1;
    &:hover {
      background-color: $primary-neutral-blue;
    }
    &:disabled {
      background-color: $neutrals-greys-grey-7;
      color: $neutrals-greys-grey-1;
      &:hover {
        background-color: $neutrals-greys-grey-7 !important;
        color: $neutrals-greys-grey-1 !important;
      }
    }
  }
}

.toggle-button,
.toggle-button:hover,
.toggle-button:focus,
.toggle-button:active {
  height: calc-input-height();
  top: inherit;
  width: 100%;
  font-weight: $input-font-weight;
  z-index: 10;
  color: $primary-trust-dark-blue;
  background-color: $extended-cobalt-cobalt-tint-04 !important;
  line-height: 1;
  font-size: $toggle-text-font-size;
  &.checked {
    background-color: $extended-cobalt-cobalt-tint-02 !important;
  }
}
.tooltip-info {
  top: 0;
  right: 0;
}
.password-visibility {
  line-height: 1;
  margin-top: (calc-input-height()- rem-calc(16))/2 + rem-calc(16);
  i {
    vertical-align: top;
  }
  a {
    color: $light-grey-blue;
  }
  @media #{$small-only} {
    text-align: right;
    margin-right: rem-calc(5);
    margin-bottom: rem-calc(11);
    margin-top: rem-calc(-15);
  }
}
.agreement {
  list-style: square;
  li {
    color: $light-grey-blue;
    line-height: 1.5;
    margin-bottom: rem-calc(20);
  }
}
.list-statements {
  li {
    margin-bottom: rem-calc(10);
    font-weight: normal;
    span {
      color: $black;
    }
  }
}
.important-info {
  font-size: rem-calc(14);
  font-weight: 300;
  color: #aaaaaa;
  padding: 5px;
}
.thank-you {
  .button {
    &.default {
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }
}
.apply-header {
  .call-icon {
    padding: rem-calc(12);
    background-color: #F2F4FE;
    border-radius: rem-calc(30);
    margin-right: rem-calc(8);
  }
  .phone-header {
    color: $primary-achieve-blue;
    font-size: rem-calc(18);
    font-weight: 700 !important;
    line-height: 140%;
    &:hover {
      color: $primary-achieve-blue;
    }
  }
}

.fa-submit {
  vertical-align: bottom;
  font-weight: bold;
  padding-left: rem-calc(5);
}

.apply-footer {
  padding: 40px 32px 48px 32px;
  background-color:$neutrals-greys-grey-8;
  font-family: "Open Sans";
  font-size: 14px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  a {
    text-decoration: underline;
  }
  div,
  h6,
  a {
    color: $neutrals-greys-grey-1;
  }
  .text-color {
    color: #8f9dd4;
    font-size: 12px;
  }
  h6 {
    margin-bottom: 16px;
    margin-top: 12px;
  }
  .has-tip {
    font-size: 10px;
    padding: rem-calc(10) 0;
    color: #b4b4b4;
  }
  .has-tip:hover {
    text-decoration: underline;
    color: #0078a0;
  }
  .small-text {
    color: #b4b4b4;
    font-size: rem-calc(12);
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: rem-calc(80);
  }
  .text-left {
    z-index: 1;
  }
  .links {
    a {
      margin-right: rem-calc(5);
      text-decoration: inherit;
      @media #{$small-only} {
        margin-bottom: rem-calc(5);
      }
    }
    margin-top: 17px;
    img {
      height: rem-calc(28);
      vertical-align: inherit;
    }
  }
  .footer-box{
    display: flex;
    max-width: rem-calc(1024);
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    margin: 0 auto;
  }
  .footer-images{
    display: flex;
    align-items: center;
    gap: 32px;
    align-self: stretch;
  }
}
.disclosure {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .small-1 {
    @media #{$medium-up} {
      width: 4%;
    }
    line-height: 1;
  }
  .small-11 {
    @media #{$medium-up} {
      width: 96%;
    }
  }
}
