$tab-border-bottom : 1px solid #52b0fd !default;
$tabs-navigation-active-bg-img:inherit!default;
$tabs-navigation-active-border : 1px solid #52b0fd !default;
$tabs-navigation-border : 1px solid #ebebeb !default;
$tabs-navigation-active-border-bottom : 1px solid transparent !default;
$tabs-navigation-border-bottom : 1px solid #52b0fd !default;
$tabs-navigation-cnt-border-bottom:  1px solid #52b0fd !default;
$tabs-navigation-cnt-padding : 0 0 0 30px !default;
$tabs-navigation-margin : 0 10px 0 0 !default;
$tabs-navigation-top-left-radius : 5px !default;
$tabs-navigation-top-right-radius : 5px !default;


.tabs {
  border-bottom: $tabs-navigation-cnt-border-bottom;
  padding: $tabs-navigation-cnt-padding;
  .tab-title{
    top:1px;
    border: $tabs-navigation-border;
    border-bottom: $tabs-navigation-border-bottom;
    margin:$tabs-navigation-margin;
    border-top-left-radius: $tabs-navigation-top-left-radius;
    border-top-right-radius: $tabs-navigation-top-right-radius;
    a{
      border-top-left-radius: $tabs-navigation-top-left-radius;
      border-top-right-radius: $tabs-navigation-top-right-radius;
    }
    &.active{

      border: $tabs-navigation-active-border;
      border-bottom:none;
      a{
        padding-bottom:rem-calc(1)+$tabs-navigation-padding;

        background-image:$tabs-navigation-active-bg-img;
      }
    }
  }
}