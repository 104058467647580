$subheader-font-family:$body-font-family!default;

.error-message-box {
	display: flex;
	justify-content: center;
	padding-top: 40px;
}

.subheader{
	font-family:$subheader-font-family;
	font-size: 15px;
	line-height: 170%;
	max-width: 400px;
}