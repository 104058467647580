.password-confirmation{
  .subheader{
    font-size: rem-calc(17);
    font-weight: 600;
    margin-bottom: 0;
    margin-top: rem-calc(20);
  }
  .password-rules{
    list-style: none;
    margin-top: 0;
    margin-left: rem-calc(35);
    margin-bottom: rem-calc(30);
    li{
      &.green-text{
        color: #8dc63f!important;
        i{
          color: #8dc63f!important;
        }
      }
      color: #353434;
      font-size: rem-calc(16);
      margin-bottom: 0;
      i{
        font-size: rem-calc(30);
        position: relative;
        top: rem-calc(5);
        &.freedom-check-circle{
          color: #cccccc;
        }
      }
      span{
        color: #6d787a;
        font-size: rem-calc(14);
        display: block;
        margin-left: rem-calc(42);
        line-height: 0.8;
      }

    }
    li:last-child{
      margin-bottom: 0;
    }
  }
}

.warning-with-msg{
  //
  @extend .warning;
  div.msg-wrapper{
    display: block;
    position: absolute;
    color: $input-warning-icon-color;
    bottom: -20px;
    right: 3px;
    font-size: 14px;
    i.icon{
      font-size: 14px;
      position: relative;
      top:2px;
      @extend .#{$input-icon-font-family};
      @extend .#{$input-error-icon-class} ;
    }
  }

}
.success-with-msg{
  @extend .success;
  div.msg-wrapper{
    display: block;
    position: absolute;
    color: $input-success-icon-color;
    bottom: -20px;
    right: 3px;
    font-size: 14px;
    i.icon{
      position: relative;
      top:1px;
      font-size: 18px;
      @extend .#{$input-icon-font-family};
      @extend .#{$input-success-icon-class};
    }
  }
}
.error-with-msg{
  @extend .error;
  div.msg-wrapper{
    display: block;
    position: absolute;
    color: $input-error-icon-color;
    bottom: -20px;
    right: 3px;
    font-size: 15px;
    i.icon{
      position: relative;
      top:2px;
      font-size: 15px;
      @extend .#{$input-icon-font-family};
      @extend .#{$input-error-icon-class} ;
    }
  }
}
small.warning-with-msg,small.success-with-msg,small.error-with-msg{
  display: none;
}
div.msg-wrapper{
  display: none;
}