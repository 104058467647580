p {
  color: #aaaaaa;
  small {
    font-size: 80%;
  }
  a,
  a:hover {
    outline: none;
  }
}
h1 {
  font-weight: normal;
}
strong {
  font-weight: 400;
}

.shadow-wrapper {
  box-shadow: 0px 9px 10px -11px gray;
  z-index: 1;
  position: relative;
}

.questions-callus {
  color: $neutrals-greys-grey-1;
  font-size: rem-calc(15);
  font-weight: 400;
  line-height: 170%;
}

.light-link {
  font-size: rem-calc(14);
  color: #0096ce;
  &.small {
    font-size: rem-calc(11);
  }
}

.main {
  position: relative;
  min-height: 100%;
  .input-placeholder {
    @include form-element;
    padding: $input-top-bottom-padding $input-left-right-padding;
    height: calc-input-height();
    background: transparent;
    border-color: transparent;
    color: $input-placeholder-font-color;
    position: absolute;
    bottom: 0;
    cursor: text;
  }
  .textarea-placeholder {
    @include form-element;
    background: transparent;
    border-color: transparent;
    color: $input-placeholder-font-color;
    position: absolute;
    height: rem-calc(101);
    bottom: 0;
    cursor: text;
  }
}
.main-content {
  background-color: $neutrals-greys-grey-8;
  height: 60%;
  min-height: rem-calc(520);
  position: relative;
  padding: rem-calc(15) 0;
}

.log_in {
  .white-board {
    padding-bottom: 80px;
  }
}
.flow-content {
  padding-top: 15px;
  &.expanded {
    padding: inherit;
  }
}
.logo {
  width: rem-calc(132);
  height: rem-calc(62);
  display: inline-block;
  vertical-align: middle;
}
.phone-header {
  color: #446694;
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 1.6;
  padding-left: 0;
  .i {
    font-size: 24px !important;
  }
  a,
  a:hover {
    color: #446694;
  }
}
.underline,
.underline:hover,
.underline:focus {
  display: block;
  margin-bottom: 20px;
  text-decoration: underline;
  i {
    font-size: 30px;
    position: relative;
    top: 10px;
    right: 5px;
  }
  &.small {
    font-size: 14px;
  }
}

.header-user-info {
  line-height: 1.2;
  .small-label {
    color: #a1a1a1;
    font-size: 14px;
  }
  .email {
    font-family: "open-sans", sans-serif;
    color: #565656;
    font-size: 15px;
    text-decoration: underline;
    @media #{$small-only} {
      margin-top: 5px;
    }
  }
  .ghost,
  ghost:hover {
    //overwriting foundation default style
    border: solid 1px #9bbdd3 !important;
    background: linear-gradient(to bottom, rgba(246, 246, 246, 0.09), #ffffff);
    position: inherit;
    height: inherit;
    padding: rem-calc(10 0 10);
    top: inherit;
    i {
      font-size: 15px;
    }
    @media #{$small-only} {
      padding-right: 6px !important;
      padding-left: 6px !important;
    }
  }
}

.footer {
  border-top: 1px solid #cccccc;
  color: #b4b4b4;
  font-size: rem-calc(10);
  font-weight: normal;
  bottom: 0;
  left: 0;
  right: 0;
  padding: rem-calc(20) 0;
  line-height: 1.6;
  width: 100%;
  .text-left {
    z-index: 1;
  }
}
.loader {
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 101;

  img {
    width: rem-calc(82);
    height: rem-calc(82);
    margin: rem-calc(-85) 0 0 rem-calc(-41);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
.review {
  .label-title {
    margin-bottom: 0;
  }
  .left-side {
    padding-left: 0;
    padding-right: 20px;
  }
  .right-side {
    padding-left: 20px;
    padding-right: 0;
  }
  &.submitted {
    h1 {
      margin-top: 50px;
    }
    p {
      margin-top: -8px;
    }
  }
  .back-to-dashboard {
    position: absolute;
    top: rem-calc(22);
    font-size: rem-calc(14);
  }
}

.uneditable-label-cnt {
  border-bottom: 1px dashed #e4e4e4;
  color: #494949;
  font-size: 14px;
  font-weight: bolder;
  label {
    text-transform: capitalize;
    margin-bottom: 3px;
    font-family: $body-font-family;
    cursor: text;
  }
  .left-section {
    label {
      font-size: rem-calc(12);
      color: #7f7f7f;
    }
  }
  .right-section {
    label {
      font-size: rem-calc(14);
      color: $black;
    }
  }
}
.revise {
  .italic {
    top: 3px;
    font-style: italic;
    font-size: rem-calc(10);
  }
}

.white-board {
  background-color: white;
  margin-bottom: 30px;
  border-radius: 5px;
  .close-act {
    color: $form-label-font-color;
    position: absolute;
    top: 13px;
    margin-left: 5px;
    @media #{$small-only} {
      margin-left: 2px;
    }
  }
  .act {
    color: $form-label-font-color;
  }
}
.small-label {
  color: #989898;
  font-size: 11px;
}

.step-name-group {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  li {
    margin-bottom: 10px;
  }
}
.step-name {
  font-weight: 500;
  color: #446694;
  font-size: 14px;
  .fa-angle-right {
    padding-left: 3px;
    padding-right: 5px;
    font-size: 16px;
  }
  &.green {
    color: #80bb41;
  }
  &.red {
    color: #cd2432;
  }
  .fa-check {
    display: none;
  }
  &.disabled {
    color: #aaaaaa !important;
    opacity: 0.7;
  }
  &.checked {
    .fa-angle-right {
      display: none;
    }
    .fa-check {
      display: inline-block;
      font-size: 13px;
    }
  }
}

//scrollbar
.scrollbar-chrome {
  > .scroll-element {
    .scroll-element_track {
      background: #ebebeb;
      border: 1px solid #e1e1e1;
    }
    .scroll-bar {
      background-color: #cccccc;
      border-radius: 0;
      border: 0;
    }
    &.scroll-y {
      width: 11px;
      .scroll-bar {
        width: 10px;
        left: 1px;
      }
    }
  }
  > .scroll-content {
    &.scroll-scrolly_visible {
      left: -14px;
    }
  }
}

//iframe
.iframe {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
  display: block;
  width: 100%;
  height: 100%;
}
.privacy-notice {
  height: 3975px;
}

.lp-flow {
  .h1 {
    margin: 50px 0 50px;
  }
  .doc-section {
    margin-bottom: 40px;
    background-color: #e5eaf4;
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
    hr {
      margin: 30px 0 30px;
    }
    &.statement-section {
      margin-top: 40px;
    }
    .remove-account {
      position: absolute;
      right: 15px;
      top: 0;
      @media #{$small-only} {
        top: -30px;
      }
    }
  }
  .docs {
    padding-bottom: 20px;
  }
  .button-add {
    background-color: transparent;
    border: 1px solid #33487d;
    color: #33487d;
    font-size: rem-calc(14);
    padding: 7px 15px 5px;
    font-weight: 300;
  }
  .button-continue {
    background-color: transparent;
    border: 1px solid #21b0e9;
    color: #21b0e9;
    font-size: rem-calc(14);
    padding: 13px 30px 13px;
    font-weight: 300;
    i {
      vertical-align: middle;
      font-size: 14px;
    }
  }
  .button-submit,
  .button-save {
    background-color: #21b0e9;
    font-size: rem-calc(14);
    padding: 13px 30px 13px;
    font-weight: 300;
    i {
      vertical-align: middle;
      font-size: 14px;
    }
  }
  .button-save {
    background-color: #f97123;
  }
  .plus {
    font-size: 24px;
    vertical-align: middle;
  }
  .doc-up-submit {
    margin: 80px 0 80px;
  }
  .statement {
    border-left: 2px solid #244290;
    padding-left: 10px;
    margin-left: 1px;
    margin-bottom: 7px;
  }
}

#timeout {
  .body {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
  }

  h1 {
    margin-bottom: rem-calc(20) !important;
  }
  position: fixed;
}
#inactive {
  .freedom-clock {
    font-size: 55px;
  }
}
#continue-without-upload {
  .body {
    p {
      margin: 0;
      font-size: rem-calc(14);
    }
    h4 {
      margin: rem-calc(10);
      font-size: 28px;
      font-weight: normal;
    }
    .icon {
      color: #9bbdd3;
    }
    .text {
      font-size: rem-calc(17);
      font-weight: normal;
      margin-top: 10px;
    }
    .label-title {
      border-bottom: 1px solid #9bbdd3;
      margin-right: rem-calc(10);
      margin-left: rem-calc(10);
      font-size: rem-calc(14);
    }
    .or {
      position: absolute;
      @media #{$small-only} {
        position: relative !important;
      }
      font-size: rem-calc(16);
      right: 0;
      left: 0;
      .line {
        width: 15px;
        display: inline-block;
        border-top: 1px solid;
        position: relative;
        top: 10px;
      }
    }
    b {
      margin-bottom: rem-calc(10);
      display: block;
    }
    .fa-wrap {
      font-size: rem-calc(22);
      margin-bottom: rem-calc(10);
    }
  }
  .buttons {
    text-align: center;
    font-size: rem-calc(14);
    a {
      margin: 5px;
    }
  }
}

.tooltip-blue {
  background: $form-label-font-color;
  color: #fff;
  .nub {
    border-color: transparent transparent $form-label-font-color transparent;
  }
  &.r-tip {
    @media #{$small-only} {
      max-width: 50%;
      margin-left: 44%;
      .nub {
        margin-left: -90%;
      }
    }
  }
}

.doc-internal {
  .doc-section {
    .remove-account {
      position: relative;
      text-align: right;
      top: -25px;
    }
  }
}
#select-internal-document {
  .plus {
    position: relative;
    top: -2px;
  }
}
#annual-income-note {
  i {
    color: #ff0000;
  }
}
.sidebar {
  .link {
    cursor: pointer;
    font-size: rem-calc(16);
    margin-bottom: 15px;
    color: #446694;
    display: block;
    .oval {
      margin-right: 10px;
      position: relative;
      top: -3px;
      text-align: center;
      font-size: 12px;
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 100px;
      color: #446694;
      background: #ffffff;
      border: 1px solid #446694;
      .fa-check {
        margin-left: 2px;
        margin-top: 4px;
      }
    }
    .oval:after {
      content: "";
      width: 2px;
      height: 20px;
      position: absolute;
      border-left: 1px solid #446694;
      top: 100%;
      left: 50%;
      margin-left: -1px;
      margin-top: 1px;
    }
    &.submitted {
      .oval {
        color: #80bb41;
        background: #ffffff;
        border: 1px solid #80bb41;
      }
      .oval:after {
        border-left: 1px solid #80bb41;
      }
    }
    &.active {
      .oval {
        background: #446694;
        color: #ffffff;
        border: 1px solid #446694;
      }
      .oval:after {
        border-left: 1px solid #9bbdd3;
      }
    }
    &.disabled {
      color: #9bbdd3;
      .oval {
        color: #9bbdd3;
        border: 1px solid #9bbdd3;
      }
      .oval:after {
        border-left: 1px solid #9bbdd3;
      }
    }
    &:last-child {
      .oval:after {
        display: none;
      }
    }
  }
  @media #{$medium-only} {
    .link {
      font-size: rem-calc(13);
    }
  }
}

.has-tip {
  &.right-align {
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -1 * (rem-calc(12) + ($form-spacing/2));
    @media #{$small-only} {
      right: 7px;
    }
  }
}

.uploadDoc {
  width: 760px;
  padding-top: rem-calc(0);
  margin: auto;
  box-shadow: 0px 2px 3px -2px rgba(16, 24, 40, 0.03),
    0px 2px 9px -2px rgba(16, 24, 40, 0.09);

  @media #{$small-up} {
    width: 100%;
  }

  ol {
    display: inline-block;
    margin-bottom: rem-calc(0);
    li {
      font-weight: 300;
      font-size: 14px;
      line-height: 2.14;
      letter-spacing: 0.2px;
      color: $neutrals-black;
    }
  }
  .top-section {
    margin: rem-calc(0);
    padding: rem-calc(32) rem-calc(180);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem-calc(24);

    @media #{$small-only} {
      padding: rem-calc(32);
    }
  }
  .finicity-box {
    display: flex;
    padding: rem-calc(24) rem-calc(32) rem-calc(40) rem-calc(32);
    flex-direction: column;
    gap: rem-calc(32);
    align-items: center;
  }
  .bottom-section {
    margin: rem-calc(0);
    padding: rem-calc(8) rem-calc(120) rem-calc(48) rem-calc(120);
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media #{$small-only} {
      padding: rem-calc(8) rem-calc(16) rem-calc(48) rem-calc(16);
    }
  }
  .scrollable-div {
    color: $neutrals-greys-grey-1;
    font-size: rem-calc(13);
    text-align: left;
    line-height: 1.7;
    a {
      color: inherit;
      font-size: inherit;
      border-bottom: 1px solid #aaaaaa;
    }
  }
  .submitted {
    color: $neutrals-black;
    font-size: rem-calc(18);
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .text {
    font-size: rem-calc(14);
    color: $neutrals-greys-grey-1;
    font-weight: 300;
    line-height: 1.64;
    letter-spacing: normal;
  }
  .div-text {
    font-size: rem-calc(13);
    color: $neutrals-greys-grey-1;
    font-weight: 300;
    line-height: 1.62;
    letter-spacing: -0.2px;
    text-align: center;
    @media #{$small-only} {
      br {
        display: none;
      }
    }
  }
  p {
    font-family: "Open Sans";
    font-size: rem-calc(10);
    line-height: 1.3;
    letter-spacing: normal;
    padding-left: rem-calc(64);
    padding-right: rem-calc(77);
    text-align: left;
    margin-top: rem-calc(10);
    @media #{$small-only} {
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }
    @media #{$medium-only} {
      padding-left: rem-calc(34);
      padding-right: rem-calc(38);
    }
  }
  .finicity-btn-box {
    width: 100%;
    padding: rem-calc(0px) rem-calc(52px);

    @media #{$small-only} {
      padding: rem-calc(0);
    }
  }
  .finicity-btn {
    width: 100%;
    padding: rem-calc(15) rem-calc(24);
    color: $topbar-bg-color;
    background-color: $primary-achieve-blue;
    border-radius: 8px;
    @media #{$medium-only} {
      width: 100%;
    }
    @media #{$small-only} {
      width: 100%;
    }
    &:hover {
      color: $topbar-bg-color;
      background-color: $primary-achieve-blue;
    }
  }
  .manual-btn {
    padding: rem-calc(14.5) rem-calc(24);
    margin-bottom: rem-calc(0);
    color: $primary-trust-dark-blue;
    background-color: $extended-cobalt-cobalt-tint-04;

    &:hover {
      color: $primary-trust-dark-blue;
      background-color: $extended-cobalt-cobalt-tint-04;
    }
  }
  .upload-your-docs-box {
    padding: rem-calc(24px) rem-calc(32px) rem-calc(40px) rem-calc(32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem-calc(32px);
    .title {
      color: $neutrals-black;
    }
  }
  .or {
    position: relative;
    padding: rem-calc(12) rem-calc(0);
    .line {
      height: 1px;
      background: $neutrals-greys-grey-4;
    }
    .text {
      background-color: $neutrals-white;
      position: absolute;
      top: 0px;
      width: 48px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.iavConfirm {
  padding-left: rem-calc(21);
  padding-right: rem-calc(21);
  .text {
    font-size: rem-calc(14);
    font-weight: 300;
    line-height: normal;
    letter-spacing: rem-calc(0.4);
    color: #4a4a4a;
  }
  .btn {
    width: 100%;
    padding-top: rem-calc(5);
    padding-bottom: rem-calc(5);
    font-size: rem-calc(14);
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 25px;
    padding-left: inherit;
    padding-right: inherit;
    @media #{$small-only} {
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
    }
  }
  .column {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
    @media #{$medium-only} {
      padding-left: rem-calc(5);
      padding-right: rem-calc(5);
    }
  }
}

/*omit width:50% for auto-scaled column widths*/

.doc-up {
  .doc-up-message {
    &.doc-up-error-message {
      padding: 7px;
      bottom: inherit;
      top: 135px;
      z-index: 100;
      &::before {
        transform: rotate(180deg);
        bottom: 35px;
        top: -11px;
      }
    }
  }
}

@media #{$small-only} {
  .review {
    .left-side {
      padding-left: $column-gutter/2;
      padding-right: $column-gutter/2;
    }
    .right-side {
      padding-left: $column-gutter/2;
      padding-right: $column-gutter/2;
    }
  }
  .small-margin-top-20 {
    margin-top: 20px;
  }
  .small-margin-top-10 {
    margin-top: 10px;
  }
  .small-label {
    font-size: 10px;
    text-align: center;
  }
  dialog,
  .reveal-modal {
    width: 100vw !important;
    min-height: inherit;
  }
  //
  .main {
    .header {
      padding: rem-calc(8) 0 rem-calc(5);
      background: #d4e0f2;
    }
  }
  .phone,
  .phone:focus,
  .phone:hover {
    font-size: 40px;
  }
  .phone-header {
    font-size: 17px;
    line-height: 2;
    .i {
      font-size: 19px;
    }
  }
  .main-content {
    padding: rem-calc(20) 0;
  }
  .flow-content {
    padding: inherit;
    h1 {
      margin-top: 20px;
    }
    &.margin-top-20 {
      margin-top: rem-calc(20);
    }
    &.margin-top-100,
    &.margin-top-60,
    &.margin-top-80 {
      margin-top: rem-calc(30);
    }
  }
}
//orientation portrait
@media #{$medium-only} and (orientation: portrait) {
  .top-bar {
    .header-phone {
      margin-right: 10px;
      .phone-header {
        padding: 0 10px !important;
      }
    }
  }
}
@media #{$medium-only} {
  .statement-section {
    .column {
      &.medium-4 {
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);
      }
    }
  }
}

.dashboard {
  @extend .white-board;
  padding-left: rem-calc(39);
  padding-right: rem-calc(34);
  padding-top: rem-calc(44);
  padding-bottom: rem-calc(27);
}

.tooltip-info {
  float: right;
  top: 12px;
  right: 10px;

  svg {
    display: flex;
  }
}
.assets {
  .form-body {
    a.button {
      padding: rem-calc(12 26 13) !important;
      font-size: rem-calc(12);
      width: 100%;
    }
  }
  .text {
    color: #4a4a4a;
    font-size: rem-calc(13);
    padding-right: rem-calc(20);
    line-height: 1.62;
    font-weight: 300;
    @media #{$small-only} {
      margin-bottom: rem-calc(20);
    }
  }
}
.review {
  button[type="submit"] {
    width: 100%;
  }
  &:not(.submitted) {
    .form-footer {
      margin-top: rem-calc(32);
    }
  }
  &.submitted {
    .form-footer {
      padding-left: 27px;
      padding-top: 5px;
      font-size: 14px;
      padding-bottom: 30px;
    }
  }
}
.disclosure {
  button[type="submit"] {
    width: 100%;
  }
  .disclosure-content {
    font-size: 14px;
    div {
      margin-bottom: 10px;
    }
    ul {
      font-size: 13px;
      line-height: inherit;
      margin-top: 12px;
      li {
        margin-bottom: 10px;
      }
    }
  }
  label {
    color: #000000;
    font-size: rem-calc(14);
    line-height: 1.5;
    font-weight: normal;
  }
  .form-footer {
    border: none;
    padding-bottom: rem-calc(40);
  }
}
.statement {
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 300;
}
.statements {
  p {
    margin-bottom: 0;
  }
  .form-footer {
    margin-top: rem-calc(100);
  }
}
.additional-documents {
  h1 {
    margin-bottom: 0;
  }
  .statement {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .error {
    .select-element {
      margin: 0 !important;
    }
  }
}
.thank-you {
  .thank-you-box{
    padding: 40px 24px 64px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .submitted {
    color: $nasty-green;
    font-size: rem-calc(27);
    font-weight: 300;
    letter-spacing: 0.4px;
  }
  .completed {
    color: $neutrals-black;
    font-size: rem-calc(28);
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  .text {
    font-size: rem-calc(15);
    color: $neutrals-greys-grey-1;
    line-height: 1.62;
    letter-spacing: normal;
  }
}
.additional-required-documents {
  .application-id {
    color: #8b8b8b;
    position: absolute;
    top: rem-calc(22);
  }
  .form-header {
    margin-top: rem-calc(30);
  }
  .form-footer {
    margin-top: rem-calc(50);
  }
}
.internal {
  .application-id {
    color: #6a6a6a;
  }
  .label-title {
    text-align: left;
    color: #6a6a6a;
    font-size: rem-calc(14);
  }
}
.hints {
  margin-bottom: 50px;
  .blue-color {
    color: #446694;
    i {
      font-size: 36px;
    }
  }
  p {
    color: #446694;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 7px;
  }
  div {
    text-align: center;
  }
  .info-div {
    padding: 0 35px;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #363636;
  }
  .help-div {
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.77;
    letter-spacing: 0.2px;
    text-align: center;
    color: #313131;
  }
}

//Iphone 5s
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  html {
    font-size: 14px;
  }
}
