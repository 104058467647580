.font-18 {
  font-size: rem-calc(18) !important;
  line-height: rem-calc(21);
}
.font-16 {
  font-size: rem-calc(16) !important;
}
.font-14 {
  font-size: rem-calc(14) !important;
  line-height: rem-calc(21);
}
.font-12 {
  font-size: rem-calc(12) !important;
  line-height: rem-calc(21);
}
.font-13 {
  font-size: rem-calc(13) !important;
}
.font-10 {
  font-size: rem-calc(10) !important;
}
.font-24 {
  font-size: rem-calc(24) !important;
  line-height: rem-calc(21);
}
.font-9 {
  font-size: rem-calc(9) !important;
}
.font-26 {
  font-size: rem-calc(26) !important;
}
.font-32 {
  font-size: rem-calc(32) !important;
}
.small-font-20 {
  @media #{$small-only} {
    font-size: rem-calc(20) !important;
  }
}
.font-weight-normal {
  font-weight: normal;
}
.list--bottom-minus-18 {
  margin-bottom: -18px;
}
.color-blue {
  color: #002794;
}
.dusky-blue {
  color: $dusky-blue;
}
.orange {
  color: $orange;
}
.blue {
  color: $primary-color;
}
.black {
  color: #000000;
}
.gray {
  color: #7f7f7f;
  line-height: 1.8;
  letter-spacing: 0.2px;
}
.color-red {
  color: $alert-color;
  &:hover {
    color: $alert-color !important;
  }
  &:focus {
    color: $alert-color !important;
  }
}
.light-text {
  color: $black;
  line-height: 1.62;
}
.dagger {
  position: absolute;
  top: -2px;
}
.margin-bottom-40 {
  margin-bottom: rem-calc(40) !important;
}
.margin-bottom-20 {
  margin-bottom: rem-calc(20) !important;
}
.margin-bottom-50 {
  margin-bottom: rem-calc(50) !important;
}
.margin-bottom-10 {
  margin-bottom: rem-calc(10) !important;
}
.margin-top-30 {
  margin-top: rem-calc(30) !important;
}
.margin-bottom-35 {
  margin-bottom: rem-calc(35) !important;
}
.margin-top-20 {
  margin-top: rem-calc(20) !important;
}
.margin-top-10 {
  margin-top: rem-calc(10) !important;
}
.margin-top-5 {
  margin-top: rem-calc(5) !important;
}
.margin-bottom-5 {
  margin-bottom: rem-calc(5) !important;
}
.margin-top-25 {
  margin-top: rem-calc(25) !important;
}
.margin-top-40 {
  margin-top: rem-calc(40) !important;
}
.margin-top-80 {
  margin-top: rem-calc(80) !important;
}

.margin-right-24 {
  margin-right: rem-calc(24) !important;
}
.margin-bottom-30 {
  margin-bottom: rem-calc(30) !important;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-15 {
  margin-bottom: rem-calc(15) !important;
}
input.margin-bottom-0 {
  margin-bottom: 0;
}
.padding-0 {
  padding: 0 !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-left-large-0 {
  @media #{$large-up} {
    padding-left: 0 !important;
  }
}
.padding-right-large-0 {
  @media #{$large-up} {
    padding-right: 0 !important;
  }
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-left-0 {
  padding-left: 0;
  padding-right: 0;
}
.padding-top-bottom-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.margin-top-100 {
  margin-top: rem-calc(100) !important;
}
.margin-bottom-100 {
  margin-bottom: rem-calc(100) !important;
}
.margin-top-60 {
  margin-top: rem-calc(60) !important;
}
.margin-top-50 {
  margin-top: rem-calc(50) !important;
}
.margin-top-80 {
  margin-top: rem-calc(80) !important;
}
.margin-left-10 {
  margin-left: rem-calc(10) !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.padding-right-10 {
  padding-right: rem-calc(10) !important;
}
.padding-left-10 {
  padding-left: rem-calc(10) !important;
}
.padding-top-20 {
  padding-top: rem-calc(20) !important;
}
@media #{$medium-only} {
  .medium-padding-0 {
    padding: 0 !important;
  }
}
@media #{$small-only} {
  .small-margin-top-40 {
    margin-top: rem-calc(40) !important;
  }
  .small-margin-bottom-20 {
    margin-bottom: rem-calc(20) !important;
  }
  .small-padding-left-right-30 {
    padding-left: rem-calc(30) !important;
    padding-right: rem-calc(30) !important;
  }
  .small-padding-left-right-20 {
    padding-left: rem-calc(20) !important;
    padding-right: rem-calc(20) !important;
  }
  .small-padding-0 {
    padding: rem-calc(0) !important;
  }
  .small-padding-10 {
    padding: rem-calc(10) !important;
  }
  .small-padding-bottom-10 {
    padding-bottom: rem-calc(10) !important;
  }
}
.inline-block {
  display: inline-block;
}
.dotted-border {
  border-radius: 3px;
  border: 1px dashed #9bbdd3;
}
.solid-border {
  border-radius: 10px;
  border: 1px solid $neutrals-greys-grey-5;
}
.padding-5 {
  padding: rem-calc(5) !important;
}
.padding-10 {
  padding: rem-calc(10) !important;
}
