$table-width : 100%!default;
$table-border-bottom-size:1px!default;
$table-border-bottom-color:#d8d8d8!default;

table {
  width: $table-width;
  font-family: $button-font-family;
  @mixin tableBorder(){
    border-bottom: solid $table-border-bottom-size $table-border-bottom-color;
  }
  thead{
    tr{
      @include tableBorder()
    }
  }
  tbody{
    tr:not(:last-child){
      @include tableBorder()
    }
  }
  th,td{
    width : 19%;
    &:last-child{
      width : 24%;
    }
    &:not(:first-child){
      text-align: center;
    }
  }
  td{
    &:first-child{
      color: #3582b8;
    }
    &.not-complete{
      color: #909090;
    }
    &.completed{
      color: #80bb41;
    }
    &.required{
      font-size: 13px;
      color: #d0021b;
    }
    button, .button{
      margin: 0;
      font-size: 12px;
      padding: 7px 19px 8px 16px;
      width: 100%;
    }
  }
}