//
// FOUNDATION SETTINGS
//

// This is the default html and body font-size for the base rem value.
// $rem-base: 16px;

// Allows the use of rem-calc() or lower-bound() in your settings
@import "../foundation/functions";

$form-label-small-transform: none;

// $experimental: true;

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with browser-based text zoom or user-set defaults.

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be different and not have it affect the grid breakpoints,
// set $rem-base to $base-font-size and make sure $base-font-size is a px value.
// $base-font-size: 100%;

// The $base-line-height is 100% while $base-font-size is 150%
// $base-line-height: 150%;

// We use this to control whether or not CSS classes come through in the gem files.
$include-html-classes: true;
$include-print-styles: true;
$include-html-global-classes: $include-html-classes;

// Grid
// $include-html-grid-classes: $include-html-classes;
// $include-xl-html-grid-classes: false;

$row-width: rem-calc(960);
$column-gutter: rem-calc(20);
// $total-columns: 12;

// We use these to control various global styles
//$body-bg: url("/images/background.jpg");
// $body-font-color: #222;
$body-font-family: "Merriweather Sans";
$body-font-weight: lighter;
// $body-font-style: normal;

// We use this to control font-smoothing
// $font-smoothing: antialiased;

// We use these to control text direction settings
// $text-direction: ltr;
// $opposite-direction: right;
// $default-float: left;

// We use these as default colors throughout
$primary-color: #3582b8;
$secondary-color: #c9c9c9;
$alert-color: #ff0120;
$success-color: #43ac6a;
$warning-color: #f08a24;
$info-color: #a0d3e8;
$greyish-brown: #4a4a4a;
$dusky-blue: #446694;
$windows-blue: #446694;
$nasty-green: #80bb41;
$light-grey-blue: #9bbdd3;
$orange: #f97123;
$black: #4a4a4a;
// new colors
$neutrals-black: #000;
$neutrals-white: #fff;
$neutrals-greys-grey-1: #616a7c;
$neutrals-greys-grey-4: #bec6d5;
$neutrals-greys-grey-5: #d7deeb;
$neutrals-greys-grey-7: #eef0f9;
$neutrals-greys-grey-8: #f8f8fc;

$primary-achieve-blue: #30f;
$primary-neutral-blue: #99ccff;
$primary-trust-dark-blue: #041b93;
$extended-cobalt-cobalt-tint-02: #b8c7ff;
$extended-cobalt-cobalt-tint-04: #f0f3ff;
$primary-trust-dark-blue: #041b93;
$neutrals-warm-black: #1F1F23;

$functional-error-error-red: #cb000e;
$primary-neutral-blue: #9cf;

// We use these to make sure border radius matches unless we want it different.
$global-radius: 3px;
$global-rounded: 1000px;

// We use these to control inset shadow shiny edges and depressions.
// $shiny-edge-size: 0 1px 0;
// $shiny-edge-color: rgba(#fff, .5);
// $shiny-edge-active-color: rgba(#000, .2);

// Here we define the breakpoints which will become the upper border for each media size.
// The function em-calc() calculates the em-value from a px-value.
$small-breakpoint: em-calc(737) !default;
$medium-breakpoint: em-calc(1024) !default;
$large-breakpoint: em-calc(1440) !default;
$xlarge-breakpoint: em-calc(1920) !default;

// Here we define the lower and upper bounds for each media size
$small-range: (0, $small-breakpoint) !default; /* 0, 640px */
$medium-range: (
  $small-breakpoint + em-calc(1),
  $medium-breakpoint
) !default; /* 641px, 1024px */
$large-range: (
  $medium-breakpoint + em-calc(1),
  $large-breakpoint
) !default; /* 1025px, 1440px */
$xlarge-range: (
  $large-breakpoint + em-calc(1),
  $xlarge-breakpoint
) !default; /* 1441px, 1920px */
$xxlarge-range: (
  $xlarge-breakpoint + em-calc(1),
  em-calc(99999999)
) !default; /* 1921px, ... */

// $screen: "only screen";

// $landscape: "#{$screen} and (orientation: landscape)";
// $portrait: "#{$screen} and (orientation: portrait)";

// $small-up: $screen;
// $small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";

// $medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
// $medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

// $large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
// $large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

// $xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
// $xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

// $xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
// $xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";

// Legacy
// $small: $medium-up;
// $medium: $medium-up;
// $large: $large-up;

//We use this as cursors values for enabling the option of having custom cursors in the whole site's stylesheet
// $cursor-crosshair-value: crosshair;
// $cursor-default-value: default;
// $cursor-pointer-value: pointer;
// $cursor-help-value: help;
// $cursor-text-value: text;

//
// TYPOGRAPHY
//

// $include-html-type-classes: $include-html-classes;

// We use these to control header font styles
$header-font-weight: 300;
// $header-font-style: normal;
$header-font-color: #4a4a4a;
//for style guide
$header-top-margin: rem-calc(25);
$header-bottom-margin: rem-calc(25);
$header-text-rendering: auto;

// We use these to control header font sizes
$h1-font-size: rem-calc(27);
//$h2-font-size: rem-calc(27);
// $h3-font-size: rem-calc(27);
$h4-font-size: rem-calc(21);
$h5-font-size: rem-calc(14);
$h6-font-size: 1rem;

$h1-font-reduction: rem-calc(0);
$h2-font-reduction: rem-calc(3);
$h4-font-reduction: rem-calc(7);
$h5-font-reduction: rem-calc(0);
$h6-font-reduction: rem-calc(0);

// These control how subheaders are styled.
// $subheader-line-height: 1.4;
$subheader-font-color: $header-font-color;
// $subheader-top-margin: .2rem;
$subheader-font-family: $body-font-family;
$subheader-font-weight: 300;
$subheader-bottom-margin: rem-calc(20);

// A general <small> styling
// $small-font-size: 60%;
// $small-font-color: scale-color($header-font-color, $lightness: 35%);

// We use these to style paragraphs
// $paragraph-font-family: inherit;
$paragraph-font-weight: lighter;
$paragraph-font-size: rem-calc(12);
$paragraph-line-height: 1.2;
$paragraph-margin-bottom: rem-calc(20);
// $paragraph-aside-font-size: rem-calc(14);
// $paragraph-aside-line-height: 1.35;
// $paragraph-aside-font-style: italic;
$paragraph-text-rendering: auto;

// We use these to style <code> tags
// $code-color: scale-color($alert-color, $lightness: -27%);
// $code-font-family: Consolas, 'Liberation Mono', Courier, monospace;
// $code-font-weight: bold;

// We use these to style anchors
// $anchor-text-decoration: none;
// $anchor-font-color: $primary-color;
// $anchor-font-color-hover: scale-color($primary-color, $lightness: -14%);

// We use these to style the <hr> element
// $hr-border-width: 1px;
// $hr-border-style: solid;
// $hr-border-color: #ddd;
// $hr-margin: rem-calc(20);

// We use these to style lists
// $list-style-position: outside;
// $list-side-margin: 1.1rem;
// $list-ordered-side-margin: 1.4rem;
// $list-side-margin-no-bullet: 0;
// $list-nested-margin: rem-calc(20);
// $definition-list-header-weight: bold;
// $definition-list-header-margin-bottom: .3rem;
// $definition-list-margin-bottom: rem-calc(12);

// We use these to style blockquotes
// $blockquote-font-color: scale-color($header-font-color, $lightness: 35%);
// $blockquote-padding: rem-calc(9 20 0 19);
// $blockquote-border: 1px solid #ddd;
// $blockquote-cite-font-size: rem-calc(13);
// $blockquote-cite-font-color: scale-color($header-font-color, $lightness: 23%);
// $blockquote-cite-link-color: $blockquote-cite-font-color;

// Acronym styles
// $acronym-underline: 1px dotted #ddd;

// We use these to control padding and margin
// $microformat-padding: rem-calc(10 12);
// $microformat-margin: rem-calc(0 0 20 0);

// We use these to control the border styles
// $microformat-border-width: 1px;
// $microformat-border-style: solid;
// $microformat-border-color: #ddd;

// We use these to control full name font styles
// $microformat-fullname-font-weight: bold;
// $microformat-fullname-font-size: rem-calc(15);

// We use this to control the summary font styles
// $microformat-summary-font-weight: bold;

// We use this to control abbr padding
// $microformat-abbr-padding: rem-calc(0 1);

// We use this to control abbr font styles
// $microformat-abbr-font-weight: bold;
// $microformat-abbr-font-decoration: none;

// Accordion

// $include-html-accordion-classes: $include-html-classes;

$accordion-navigation-padding: rem-calc(20);
$accordion-navigation-bg-color: #8dc63f;
$accordion-navigation-hover-bg-color: scale-color(
  $accordion-navigation-bg-color,
  $lightness: -8%
);
$accordion-navigation-active-bg-color: scale-color(
  $accordion-navigation-bg-color,
  $lightness: -5%
);
$accordion-navigation-font-color: #ffffff;
$accordion-navigation-font-size: rem-calc(14);
// $accordion-navigation-font-family: $body-font-family;

$accordion-content-padding: 0 0 0 rem-calc(28);
$accordion-content-active-bg-color: #8dc63f;

// Alert Boxes

// $include-html-alert-classes: $include-html-classes;

// We use this to control alert padding.
// $alert-padding-top: rem-calc(14);
// $alert-padding-default-float: $alert-padding-top;
// $alert-padding-opposite-direction: $alert-padding-top + rem-calc(10);
// $alert-padding-bottom: $alert-padding-top;

// We use these to control text style.
// $alert-font-weight: normal;
// $alert-font-size: rem-calc(13);
// $alert-font-color: #fff;
// $alert-font-color-alt: scale-color($secondary-color, $lightness: -66%);

// We use this for close hover effect.
// $alert-function-factor: -14%;

// We use these to control border styles.
// $alert-border-style: solid;
// $alert-border-width: 1px;
// $alert-border-color: scale-color($primary-color, $lightness: $alert-function-factor);
// $alert-bottom-margin: rem-calc(20);

// We use these to style the close buttons
// $alert-close-color: #333;
// $alert-close-top: 50%;
// $alert-close-position: rem-calc(5);
// $alert-close-font-size: rem-calc(22);
// $alert-close-opacity: 0.3;
// $alert-close-opacity-hover: 0.5;
// $alert-close-padding: 9px 6px 4px;

// We use this to control border radius
// $alert-radius: $global-radius;

// Block Grid

// $include-html-grid-classes: $include-html-classes;

// We use this to control the maximum number of block grid elements per row
// $block-grid-elements: 12;
// $block-grid-default-spacing: rem-calc(20);

// Enables media queries for block-grid classes. Set to false if writing semantic HTML.
// $block-grid-media-queries: true;

//$date-row-width: $row-width;
$date-column-gutter: rem-calc(9);
//$date-total-columns: $total-columns ;

// Breadcrumbs

// $include-html-nav-classes: $include-html-classes;

// We use this to set the background color for the breadcrumb container.
$crumb-bg: none;

// We use these to set the padding around the breadcrumbs.
$crumb-padding: rem-calc(0 5 0 0);
$crumb-side-padding: rem-calc(4);

// We use these to control border styles.
// $crumb-function-factor: -10%;
$crumb-border-size: 0;
// $crumb-border-style: solid;
$crumb-border-color: none;
$crumb-radius: 0;

// We use these to set various text styles for breadcrumbs.
$crumb-font-size: rem-calc(10);
$crumb-font-color: #b4b4b4;
$crumb-font-color-current: #b4b4b4;
$crumb-font-color-unavailable: #b4b4b4;
$crumb-font-transform: none;
$crumb-link-decor: underline;

// We use these to control the slash between breadcrumbs
$crumb-slash-color: #b4b4b4;
$crumb-slash: "|";

//
// BUTTONS
//

// $include-html-button-classes: $include-html-classes;

// We use these to build padding for buttons.
// $button-tny: rem-calc(10);
//$button-sml: rem-calc(14);
//$button-med: rem-calc(11);
//$button-lrg: rem-calc(14);

// We use this to control the display property.
// $button-display: inline-block;
$button-margin-bottom: rem-calc(40);

// We use these to control button text styles.
$button-font-family: "Merriweather Sans";
// $button-font-color: #fff;
// $button-font-color-alt: #333;
// $button-font-tny: rem-calc(11);
$button-font-sml: rem-calc(14);
$button-font-med: rem-calc(21);
$button-font-lrg: rem-calc(24);
$button-font-weight: 300;
// $button-font-align: center;

// We use these to control various hover effects.
// $button-function-factor: 5%;

// We use these to control button border styles.
// $button-border-width: 1px;
// $button-border-style: solid;

// We use this to set the default radius used throughout the core.
// $button-radius: $global-radius;
// $button-round: $global-rounded;

// We use this to set default opacity for disabled buttons.
// $button-disabled-opacity: 0.7;

$button-primary-bg: #f97123;
$button-primary-hover-bg: #eb4519;

$button-tertiary-bg: #87d1d0;

$button-secondary-bg: $secondary-color;

// Button Groups

// $include-html-button-classes: $include-html-classes;

// Sets the margin for the right side by default, and the left margin if right-to-left direction is used
// $button-bar-margin-opposite: rem-calc(10);
// $button-group-border-width: 1px;

// Clearing

// $include-html-clearing-classes: $include-html-classes;

// We use these to set the background colors for parts of Clearing.
// $clearing-bg: #333;
// $clearing-caption-bg: $clearing-bg;
// $clearing-carousel-bg: rgba (51,51,51,0.8);
// $clearing-img-bg: $clearing-bg;

// We use these to style the close button
// $clearing-close-color: #ccc;
// $clearing-close-size: 30px;

// We use these to style the arrows
// $clearing-arrow-size: 12px;
// $clearing-arrow-color: $clearing-close-color;

// We use these to style captions
// $clearing-caption-font-color: #ccc;
// $clearing-caption-font-size: 0.875em;
// $clearing-caption-padding: 10px 30px 20px;

// We use these to make the image and carousel height and style
// $clearing-active-img-height: 85%;
// $clearing-carousel-height: 120px;
// $clearing-carousel-thumb-width: 120px;
// $clearing-carousel-thumb-active-border: 1px solid rgb(255,255,255);

// Dropdown

// $include-html-dropdown-classes: $include-html-classes;

// We use these to controls height and width styles.
// $f-dropdown-max-width: 200px;
// $f-dropdown-height: auto;
// $f-dropdown-max-height: none;
// $f-dropdown-margin-top: 2px;

// We use this to control the background color
// $f-dropdown-bg: #fff;

// We use this to set the border styles for dropdowns.
// $f-dropdown-border-style: solid;
// $f-dropdown-border-width: 1px;
// $f-dropdown-border-color: scale-color(#fff, $lightness: -20%);

// We use these to style the triangle pip.
// $f-dropdown-triangle-size: 6px;
// $f-dropdown-triangle-color: #fff;
// $f-dropdown-triangle-side-offset: 10px;

// We use these to control styles for the list elements.
// $f-dropdown-list-style: none;
// $f-dropdown-font-color: #555;
// $f-dropdown-font-size: rem-calc(14);
// $f-dropdown-list-padding: rem-calc(5, 10);
// $f-dropdown-line-height: rem-calc(18);
$f-dropdown-list-hover-bg: #e4eeff;
// $dropdown-mobile-default-float: 0;

// We use this to control the styles for when the dropdown has custom content.
// $f-dropdown-content-padding: rem-calc(20);

// Dropdown Buttons

// $include-html-button-classes: $include-html-classes;

// We use these to set the color of the pip in dropdown buttons
// $dropdown-button-pip-color: #fff;
// $dropdown-button-pip-color-alt: #333;

// $button-pip-tny: rem-calc(6);
// $button-pip-sml: rem-calc(7);
// $button-pip-med: rem-calc(9);
// $button-pip-lrg: rem-calc(11);

// We use these to style tiny dropdown buttons
// $dropdown-button-padding-tny: $button-pip-tny * 7;
// $dropdown-button-pip-size-tny: $button-pip-tny;
// $dropdown-button-pip-opposite-tny: $button-pip-tny * 3;
// $dropdown-button-pip-top-tny: -$button-pip-tny / 2 + rem-calc(1);

// We use these to style small dropdown buttons
// $dropdown-button-padding-sml: $button-pip-sml * 7;
// $dropdown-button-pip-size-sml: $button-pip-sml;
// $dropdown-button-pip-opposite-sml: $button-pip-sml * 3;
// $dropdown-button-pip-top-sml: -$button-pip-sml / 2 + rem-calc(1);

// We use these to style medium dropdown buttons
// $dropdown-button-padding-med: $button-pip-med * 6 + rem-calc(3);
// $dropdown-button-pip-size-med: $button-pip-med - rem-calc(3);
// $dropdown-button-pip-opposite-med: $button-pip-med * 2.5;
// $dropdown-button-pip-top-med: -$button-pip-med / 2 + rem-calc(2);

// We use these to style large dropdown buttons
// $dropdown-button-padding-lrg: $button-pip-lrg * 5 + rem-calc(3);
// $dropdown-button-pip-size-lrg: $button-pip-lrg - rem-calc(6);
// $dropdown-button-pip-opposite-lrg: $button-pip-lrg * 2.5;
// $dropdown-button-pip-top-lrg: -$button-pip-lrg / 2 + rem-calc(3);

// Flex Video

// $include-html-media-classes: $include-html-classes;

// We use these to control video container padding and margins
// $flex-video-padding-top: rem-calc(25);
// $flex-video-padding-bottom: 67.5%;
// $flex-video-margin-bottom: rem-calc(16);

// We use this to control widescreen bottom padding
// $flex-video-widescreen-padding-bottom: 57.25%;

// Forms

// $include-html-form-classes: $include-html-classes;

// We use this to set the base for lots of form spacing and positioning styles
$form-spacing: rem-calc(11);

// We use these to style the labels in different ways
// $form-label-pointer: pointer;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: 300;
// $form-label-line-height: 1.5;
$form-label-font-color: #4a4a4a;
// $form-label-bottom-margin: 0;
$input-font-family: "open-sans", sans-serif;
//$input-font-weight : normal;
$input-font-color: #4a4a4a;
$input-font-size: rem-calc(14);
$input-bg-color: #fff;
$input-focus-bg-color: #fff;
$input-border-color: #9bbdd3;
$input-focus-border-color: #8ca6bc;
// $input-border-style: solid;
// $input-border-width: 1px;
$input-border-radius: $global-radius;
$input-disabled-bg: #f3f3f3;
$input-disabled-border-color: #bdbdbd;
$input-disabled-color: #9b9b9b;
$input-box-shadow: none;
$input-include-glowing-effect: true;
$input-placeholder-font-color: #9b9b9b;

// We use these to style the fieldset border and spacing.
// $fieldset-border-style: solid;
// $fieldset-border-width: 1px;
// $fieldset-border-color: #ddd;
// $fieldset-padding: rem-calc(20);
// $fieldset-margin: rem-calc(18 0);

// We use these to style the legends when you use them
// $legend-bg: #fff;
// $legend-font-weight: bold;
// $legend-padding: rem-calc(0 3);

// We use these to style the prefix and postfix input elements
$input-prefix-bg: $input-bg-color;
$input-prefix-border-color: $input-border-color;
$input-prefix-border-size: 1px;
$input-prefix-border-type: solid;
$input-prefix-overflow: hidden;
$input-prefix-font-color: #446694;
$input-prefix-font-color-alt: #446694;
$prefix-postfix-font-size: rem-calc(24);

// We use these to style the error states for inputs and labels
$input-error-message-padding: rem-calc(4 3 3);
$input-error-message-top: 0;
$input-error-message-font-size: rem-calc(11);
$input-error-message-line-height: 1;
$input-error-message-font-weight: normal;
$input-error-message-font-style: normal;
$input-error-message-font-color: #ed1c24;
$input-error-message-font-color-alt: #333;
$input-error-message-bottom: 0;

//Additional properties
$input-error-message-bg-color: transparent;
$input-icon-font-family: "freedom";

$input-error-font-color: #ed1c24;
$input-error-border-color: #ed1c24;
$input-error-icon: true;
$input-error-icon-class: "freedom-alert-solid";
$input-error-icon-color: #ed1c24;
$input-error-icon-offset-right: rem-calc(0);
$input-error-icon-offset-bottom: rem-calc(9);
$input-error-icon-font-size: rem-calc(30);
$input-error-focus-bg-color: $input-focus-bg-color;
$input-error-focus-border-color: $input-error-border-color;
$input-error-include-glowing-effect: true;
$glowing-effect-error-color: $input-error-focus-border-color !default;

$input-success-font-color: $input-font-color;
$input-success-border-color: $input-border-color;
$input-success-icon: true;
$input-success-icon-color: #359730;
$input-success-icon-offset: rem-calc(0);
$input-success-icon-font-size: rem-calc(30);
$input-success-icon-class: "freedom-check";
// We use this to style the glowing effect of inputs when focused
// $glowing-effect-fade-time: 0.45s;
// $glowing-effect-color: $input-focus-border-color;

$input-warning-include-glowing-effect: false;
$input-warning-border-color: $input-border-color;
$input-warning-font-color: $input-font-color;

$input-top-bottom-padding: rem-calc(14);
$input-left-right-padding: rem-calc(13);

// Select variables
$select-bg-color: #fff;
$select-hover-bg-color: #fff;
$select-arrow-width: 38px;
$select-arrow-color: #3582b8;

$checkbox-color: #446694;
$checkbox-icon-font-family: "freedom";
$checkbox-icon: "freedom-check";
$checkbox-icon-font-size: 18px;
$checkbox-icon-offset-top: 3px;
$checkbox-icon-offset-left: 2px;
$include-checkbox-hover-shadow: true;
$checkbox-hover-shadow: 0px 0px 5px 1px rgba(29, 174, 239, 0.4);

$radio-color: #446694;
$radio-diameter: 22px;
$radio-circle-diameter: 12px;
$include-radio-hover-shadow: true;
$radio-hover-shadow: 0px 0px 5px 1px rgba(29, 174, 239, 0.4);

// Inline Lists

// $include-html-inline-list-classes: $include-html-classes;

// We use this to control the margins and padding of the inline list.
// $inline-list-top-margin: 0;
// $inline-list-opposite-margin: 0;
// $inline-list-bottom-margin: rem-calc(17);
// $inline-list-default-float-margin: rem-calc(-22);

// $inline-list-padding: 0;

// We use this to control the overflow of the inline list.
// $inline-list-overflow: hidden;

// We use this to control the list items
// $inline-list-display: block;

// We use this to control any elments within list items
// $inline-list-children-display: block;

// Joyride

// $include-html-joyride-classes: $include-html-classes;

// Controlling default Joyride styles
// $joyride-tip-bg: #333;
// $joyride-tip-default-width: 300px;
// $joyride-tip-padding: rem-calc(18 20 24);
// $joyride-tip-border: solid 1px #555;
// $joyride-tip-radius: 4px;
// $joyride-tip-position-offset: 22px;

// Here, we're setting the tip dont styles
// $joyride-tip-font-color: #fff;
// $joyride-tip-font-size: rem-calc(14);
// $joyride-tip-header-weight: bold;

// This changes the nub size
// $joyride-tip-nub-size: 10px;

// This adjusts the styles for the timer when its enabled
// $joyride-tip-timer-width: 50px;
// $joyride-tip-timer-height: 3px;
// $joyride-tip-timer-color: #666;

// This changes up the styles for the close button
// $joyride-tip-close-color: #777;
// $joyride-tip-close-size: 24px;
// $joyride-tip-close-weight: normal;

// When Joyride is filling the screen, we use this style for the bg
// $joyride-screenfill: rgba(0,0,0,0.5);

// Keystrokes

// $include-html-type-classes: $include-html-classes;

// We use these to control text styles.
// $keystroke-font: "Consolas", "Menlo", "Courier", monospace;
// $keystroke-font-size: rem-calc(14);
// $keystroke-font-color: #222;
// $keystroke-font-color-alt: #fff;
// $keystroke-function-factor: -7%;

// We use this to control keystroke padding.
// $keystroke-padding: rem-calc(2 4 0);

// We use these to control background and border styles.
// $keystroke-bg: scale-color(#fff, $lightness: $keystroke-function-factor);
// $keystroke-border-style: solid;
// $keystroke-border-width: 1px;
// $keystroke-border-color: scale-color($keystroke-bg, $lightness: $keystroke-function-factor);
// $keystroke-radius: $global-radius;

// Labels

// $include-html-label-classes: $include-html-classes;

// We use these to style the labels
// $label-padding: rem-calc(4 8 6);
// $label-radius: $global-radius;

// We use these to style the label text
// $label-font-sizing: rem-calc(11);
// $label-font-weight: normal;
// $label-font-color: #333;
// $label-font-color-alt: #fff;
$label-font-family: $input-font-family;

// Magellan

// $include-html-magellan-classes: $include-html-classes;

// $magellan-bg: #fff;
// $magellan-padding: 10px;

// Off-canvas

$tabbar-bg: #ffffff;
$tabbar-height: rem-calc(80);
$tabbar-icon-width: rem-calc(80);
// $tabbar-line-height: $tabbar-height;
$tabbar-color: grey;
// $tabbar-middle-padding: 0 rem-calc(10);

// Off Canvas Divider Styles
$tabbar-right-section-border: solid 0px scale-color($tabbar-bg, $lightness: 13%);
$tabbar-left-section-border: solid 0px scale-color($tabbar-bg, $lightness: -50%);

// Off Canvas Tab Bar Headers
// $tabbar-header-color: #FFF;
// $tabbar-header-weight: bold;
// $tabbar-header-line-height: $tabbar-height;
// $tabbar-header-margin: 0;

// Off Canvas Menu Variables
// $off-canvas-width: 250px;
$off-canvas-bg: #8dc63f;
$off-canvas-bg-hover: #8dc63f;
// Off Canvas Menu List Variables
$off-canvas-label-padding: rem-calc(20) rem-calc(20);
$off-canvas-label-color: #ffffff;
$off-canvas-label-text-transform: inherit;
$off-canvas-label-font-weight: normal;
$off-canvas-label-bg: #8dc63f;
$off-canvas-label-border-top: 0px solid scale-color(#444, $lightness: 14%);
// $off-canvas-label-border-bottom: none;
$off-canvas-label-font-size: rem-calc(14);
// $off-canvas-label-margin:0;
$off-canvas-link-padding: rem-calc(20) rem-calc(20);
$off-canvas-link-color: #daf7b2;
$off-canvas-link-border-bottom: 0px solid
  scale-color($off-canvas-bg, $lightness: -25%);

// Off Canvas Menu Icon Variables
$tabbar-menu-icon-color: #333;
// $tabbar-menu-icon-hover: scale-color($tabbar-menu-icon-color, $lightness: -30%);

$tabbar-menu-icon-text-indent: rem-calc(20);
// $tabbar-menu-icon-width: $tabbar-height;
// $tabbar-menu-icon-height: $tabbar-height;
// $tabbar-menu-icon-line-height: rem-calc(33);
// $tabbar-menu-icon-padding: 0;

// $tabbar-hamburger-icon-width: rem-calc(16);
// $tabbar-hamburger-icon-left: rem-calc(13);
// $tabbar-hamburger-icon-top: rem-calc(5);

// Off Canvas Back-Link Overlay
$off-canvas-overlay-transition: background 300ms ease;
// $off-canvas-overlay-cursor: pointer;
$off-canvas-overlay-box-shadow: none;
$off-canvas-overlay-background: transparent;
// $off-canvas-overlay-background-hover: rgba(#FFF, 0.05);

// Transition Variables
// $menu-slide: "transform 500ms ease";

// Orbit

// $include-html-orbit-classes: $include-html-classes;

// We use these to control the caption styles
// $orbit-container-bg: none;
// $orbit-caption-bg: rgba(51,51,51, 0.8);
// $orbit-caption-font-color: #fff;
// $orbit-caption-font-size: rem-calc(14);
// $orbit-caption-position: "bottom"; // Supported values: "bottom", "under"
// $orbit-caption-padding: rem-calc(10 14);
// $orbit-caption-height: auto;

// We use these to control the left/right nav styles
// $orbit-nav-bg: none;
// $orbit-nav-bg-hover: rgba(0,0,0,0.3);
// $orbit-nav-arrow-color: #fff;
// $orbit-nav-arrow-color-hover: #fff;

// We use these to control the timer styles
// $orbit-timer-bg: rgba(255,255,255,0.3);
// $orbit-timer-show-progress-bar: true;

// We use these to control the bullet nav styles
// $orbit-bullet-nav-color: #ccc;
// $orbit-bullet-nav-color-active: #999;
// $orbit-bullet-radius: rem-calc(9);

// We use these to controls the style of slide numbers
// $orbit-slide-number-bg: rgba(0,0,0,0);
// $orbit-slide-number-font-color: #fff;
// $orbit-slide-number-padding: rem-calc(5);

// Graceful Loading Wrapper and preloader
// $wrapper-class: "slideshow-wrapper";
// $preloader-class: "preloader";

// Pagination

// $include-html-nav-classes: $include-html-classes;

// We use these to control the pagination container
// $pagination-height: rem-calc(24);
// $pagination-margin: rem-calc(-5);

// We use these to set the list-item properties
// $pagination-li-float: $default-float;
// $pagination-li-height: rem-calc(24);
// $pagination-li-font-color: #222;
// $pagination-li-font-size: rem-calc(14);
// $pagination-li-margin: rem-calc(5);

// We use these for the pagination anchor links
// $pagination-link-pad: rem-calc(1 10 1);
// $pagination-link-font-color: #999;
// $pagination-link-active-bg: scale-color(#fff, $lightness: -10%);

// We use these for disabled anchor links
// $pagination-link-unavailable-cursor: default;
// $pagination-link-unavailable-font-color: #999;
// $pagination-link-unavailable-bg-active: transparent;

// We use these for currently selected anchor links
// $pagination-link-current-background: $primary-color;
// $pagination-link-current-font-color: #fff;
// $pagination-link-current-font-weight: bold;
// $pagination-link-current-cursor: default;
// $pagination-link-current-active-bg: $primary-color;

// Panels

// $include-html-panel-classes: $include-html-classes;

// We use these to control the background and border styles
// $panel-bg: scale-color(#fff, $lightness: -5%);
// $panel-border-style: solid;
// $panel-border-size: 1px;

// We use this % to control how much we darken things on hover
// $panel-function-factor: -11%;
// $panel-border-color: scale-color($panel-bg, $lightness: $panel-function-factor);

// We use these to set default inner padding and bottom margin
// $panel-margin-bottom: rem-calc(20);
// $panel-padding: rem-calc(20);

// We use these to set default font colors
// $panel-font-color: #333;
// $panel-font-color-alt: #fff;

// $panel-header-adjust: true;
// $callout-panel-link-color: $primary-color;

// Pricing Tables

// $include-html-pricing-classes: $include-html-classes;

// We use this to control the border color
// $price-table-border: solid 1px #ddd;

// We use this to control the bottom margin of the pricing table
// $price-table-margin-bottom: rem-calc(20);

// We use these to control the title styles
// $price-title-bg: #333;
// $price-title-padding: rem-calc(15 20);
// $price-title-align: center;
// $price-title-color: #eee;
// $price-title-weight: normal;
// $price-title-size: rem-calc(16);
// $price-title-font-family: $body-font-family;

// We use these to control the price styles
// $price-money-bg: #f6f6f6  ;
// $price-money-padding: rem-calc(15 20);
// $price-money-align: center;
// $price-money-color: #333;
// $price-money-weight: normal;
// $price-money-size: rem-calc(32);
// $price-money-font-family: $body-font-family;

// We use these to control the description styles
// $price-bg: #fff;
// $price-desc-color: #777;
// $price-desc-padding: rem-calc(15);
// $price-desc-align: center;
// $price-desc-font-size: rem-calc(12);
// $price-desc-weight: normal;
// $price-desc-line-height: 1.4;
// $price-desc-bottom-border: dotted 1px #ddd;

// We use these to control the list item styles
// $price-item-color: #333;
// $price-item-padding: rem-calc(15);
// $price-item-align: center;
// $price-item-font-size: rem-calc(14);
// $price-item-weight: normal;
// $price-item-bottom-border: dotted 1px #ddd;

// We use these to control the CTA area styles
// $price-cta-bg: #fff;
// $price-cta-align: center;
// $price-cta-padding: rem-calc(20 20 0);

// Progress Meters

// $include-html-media-classes: $include-html-classes;

// We use this to se the prog bar height
$progress-bar-height: rem-calc(16);
$progress-bar-color: #ffffff;

// We use these to control the border styles
$progress-bar-border-color: #e3e3e3;

// $progress-bar-border-size: 1px;
// $progress-bar-border-style: solid;
$progress-bar-border-radius: $global-radius;

// We use these to control the margin & padding
$progress-bar-pad: rem-calc(1);
$progress-bar-margin-bottom: rem-calc(20);

// We use these to set the meter colors
// $progress-meter-color: $primary-color;
// $progress-meter-secondary-color: $secondary-color;
$progress-meter-success-color: #80bb41;
// $progress-meter-alert-color: $alert-color;

// Reveal

// $include-html-reveal-classes: $include-html-classes;

// We use these to control the style of the reveal overlay.
$reveal-overlay-bg: rgba(#fbfbfb, 0.6);
// $reveal-overlay-bg-old: #000;

// We use these to control the style of the modal itself.
$reveal-modal-bg: #fff;
// $reveal-position-top: 50px;
// $reveal-default-width: 80%;
$reveal-modal-padding: rem-calc(0);

// $reveal-box-shadow: 0 0 10px rgba(#000,.4);

// We use these to style the reveal close button
$reveal-close-font-size: rem-calc(14);
$reveal-close-top: rem-calc(14);
// $reveal-close-side: rem-calc(11);
$reveal-close-color: #3582b8;
$reveal-close-weight: inherit;

// We use these to control the modal border
// $reveal-border-style: solid;
// $reveal-border-width: 1px;
// $reveal-border-color: #666;

// $reveal-modal-class: "reveal-modal";
// $close-reveal-modal-class: "close-reveal-modal";

// Side Nav

// $include-html-nav-classes: $include-html-classes;

// We use this to control padding.
// $side-nav-padding: rem-calc(14 0);

// We use these to control list styles.
// $side-nav-list-type: none;
// $side-nav-list-position: inside;
// $side-nav-list-margin: rem-calc(0 0 7 0);

// We use these to control link styles.
// $side-nav-link-color: $primary-color;
// $side-nav-link-color-active: scale-color($side-nav-link-color, $lightness: 30%);
// $side-nav-link-color-hover: scale-color($side-nav-link-color, $lightness: 30%);
// $side-nav-font-size: rem-calc(14);
// $side-nav-font-weight: normal;
// $side-nav-font-family: $body-font-family;
// $side-nav-active-font-family: $side-nav-font-family;

// We use these to control border styles
// $side-nav-divider-size: 1px;
// $side-nav-divider-style: solid;
// $side-nav-divider-color: scale-color(#fff, $lightness: -10%);

// Split Buttons

// $include-html-button-classes: $include-html-classes;

// We use these to control different shared styles for Split Buttons
// $split-button-function-factor: 10%;
// $split-button-pip-color: #fff;
// $split-button-pip-color-alt: #333;
// $split-button-active-bg-tint: rgba(0,0,0,0.1);

// We use these to control tiny split buttons
// $split-button-padding-tny: $button-pip-tny * 10;
// $split-button-span-width-tny: $button-pip-tny * 6;
// $split-button-pip-size-tny: $button-pip-tny;
// $split-button-pip-top-tny: $button-pip-tny * 2;
// $split-button-pip-default-float-tny: rem-calc(-6);

// We use these to control small split buttons
// $split-button-padding-sml: $button-pip-sml * 10;
// $split-button-span-width-sml: $button-pip-sml * 6;
// $split-button-pip-size-sml: $button-pip-sml;
// $split-button-pip-top-sml: $button-pip-sml * 1.5;
// $split-button-pip-default-float-sml: rem-calc(-6);

// We use these to control medium split buttons
// $split-button-padding-med: $button-pip-med * 9;
// $split-button-span-width-med: $button-pip-med * 5.5;
// $split-button-pip-size-med: $button-pip-med - rem-calc(3);
// $split-button-pip-top-med: $button-pip-med * 1.5;
// $split-button-pip-default-float-med: rem-calc(-6);

// We use these to control large split buttons
// $split-button-padding-lrg: $button-pip-lrg * 8;
// $split-button-span-width-lrg: $button-pip-lrg * 5;
// $split-button-pip-size-lrg: $button-pip-lrg - rem-calc(6);
// $split-button-pip-top-lrg: $button-pip-lrg + rem-calc(5);
// $split-button-pip-default-float-lrg: rem-calc(-6);

// Sub Nav

// $include-html-nav-classes: $include-html-classes;

// We use these to control margin and padding
$sub-nav-list-margin: 0;
// $sub-nav-list-padding-top: rem-calc(4);

// We use this to control the definition
// $sub-nav-font-family: $body-font-family;
// $sub-nav-font-size: rem-calc(14);
$sub-nav-font-color: #333333;
// $sub-nav-font-weight: normal;
// $sub-nav-text-decoration: none;
// $sub-nav-border-radius: 3px;
// $sub-nav-font-color-hover: scale-color($sub-nav-font-color, $lightness: -25%);

// We use these to control the active item styles

// $sub-nav-active-font-weight: normal;
// $sub-nav-active-bg: $primary-color;
// $sub-nav-active-bg-hover: scale-color($sub-nav-active-bg, $lightness: -14%);
// $sub-nav-active-color: #fff;
// $sub-nav-active-padding: rem-calc(3 16);
// $sub-nav-active-cursor: default;

// $sub-nav-item-divider: "";
// $sub-nav-item-divider-margin: rem-calc(12);

//
// SWITCH
//

// $include-html-form-classes: $include-html-classes;

// Controlling border styles and background colors for the switch container
// $switch-border-color: scale-color(#fff, $lightness: -20%);
// $switch-border-style: solid;
// $switch-border-width: 1px;
// $switch-bg: #fff;

// We use these to control the switch heights for our default classes
// $switch-height-tny: 22px;
// $switch-height-sml: 28px;
// $switch-height-med: 36px;
// $switch-height-lrg: 44px;
// $switch-bottom-margin: rem-calc(20);

// We use these to control default font sizes for our classes.
// $switch-font-size-tny: 11px;
// $switch-font-size-sml: 12px;
// $switch-font-size-med: 14px;
// $switch-font-size-lrg: 17px;
// $switch-label-side-padding: 6px;

// We use these to style the switch-paddle
// $switch-paddle-bg: #fff;
// $switch-paddle-fade-to-color: scale-color($switch-paddle-bg, $lightness: -10%);
// $switch-paddle-border-color: scale-color($switch-paddle-bg, $lightness: -35%);
// $switch-paddle-border-width: 1px;
// $switch-paddle-border-style: solid;
// $switch-paddle-transition-speed: .1s;
// $switch-paddle-transition-ease: ease-out;
// $switch-positive-color: scale-color($success-color, $lightness: 94%);
// $switch-negative-color: #f5f5f5;

// Outline Style for tabbing through switches
// $switch-label-outline: 1px dotted #888;

// Tables

$include-html-table-classes: $include-html-classes;

//These control the background color for the table and even rows
$table-bg: #fff;
$table-even-row-bg: none;

//These control the table cell border style
$table-border-style: none;
$table-border-size: 1px;
$table-border-color: #ddd;

//These control the table head styles
$table-head-bg: none;
$table-head-font-size: rem-calc(12);
$table-head-font-color: #9b9b9b;
$table-head-font-weight: 300;
$table-head-padding: rem-calc(8 10 10);

//These control the row padding and font styles
$table-row-padding: rem-calc(20 10);
$table-row-font-size: rem-calc(14);
$table-row-font-color: #4a4a4a;
$table-line-height: rem-calc(18);

//These are for controlling the display and margin of tables
$table-display: table-cell;
$table-margin-bottom: rem-calc(0);

//
// TABS
//

// $include-html-tabs-classes: $include-html-classes;

// $tabs-navigation-padding: rem-calc(16);
$tabs-navigation-bg-color: #ffffff;
$tabs-navigation-active-bg-color: transparent;
$tabs-navigation-active-font-color: #222222;
$tabs-navigation-hover-bg-color: $tabs-navigation-bg-color;
$tabs-navigation-font-color: #0096ce;
$tabs-navigation-font-size: rem-calc(14);
// $tabs-navigation-font-family: $body-font-family;

// $tabs-content-margin-bottom: rem-calc(24);
// $tabs-content-padding: $column-gutter/2;

// $tabs-vertical-navigation-margin-bottom: 1.25rem;

//$tab-title-bg-color: url("../../images/background.jpg");
//$tab-title-active-bg-color: #ffffff;
$tab-border-bottom: 1px solid #52b0fd;
$tabs-navigation-active-bg-img: url("/images/background.jpg");
$tabs-navigation-active-border: 1px solid #52b0fd;
$tabs-navigation-border: 1px solid #ebebeb;
$tabs-navigation-active-border-bottom: 1px solid transparent;
$tabs-navigation-border-bottom: 1px solid #52b0fd;
$tabs-navigation-cnt-border-bottom: 1px solid #52b0fd;
$tabs-navigation-cnt-padding: 0 0 0 30px;
$tabs-navigation-margin: 0 10px 0 0;
$tabs-navigation-top-left-radius: 5px;
$tabs-navigation-top-right-radius: 5px;
// THUMBNAILS
//

// $include-html-media-classes: $include-html-classes;

// We use these to control border styles
// $thumb-border-style: solid;
// $thumb-border-width: 4px;
// $thumb-border-color: #fff;
// $thumb-box-shadow: 0 0 0 1px rgba(#000,.2);
// $thumb-box-shadow-hover: 0 0 6px 1px rgba($primary-color,0.5);

// Radius and transition speed for thumbs
// $thumb-radius: $global-radius;
// $thumb-transition-speed: 200ms;

//
// TOOLTIPS
//

$include-html-tooltip-classes: $include-html-classes;

$has-tip-border-bottom: none;
$has-tip-font-weight: normal;
$has-tip-font-color: #446694;
$has-tip-border-bottom-hover: none;
$has-tip-font-color-hover: $has-tip-font-color;
$has-tip-cursor-type: pointer;
//
$tooltip-padding: rem-calc(15);
$tooltip-bg: #fff;
$tooltip-font-size: rem-calc(12);
$tooltip-font-weight: lighter;
$tooltip-font-color: #7b7b7b;
$tooltip-line-height: 1.3;
////$tooltip-close-font-size: rem-calc(10);
////$tooltip-close-font-weight: normal;
////$tooltip-close-font-color: #777;
////$tooltip-font-size-sml: rem-calc(14);
//$tooltip-radius: $global-radius;
//$tooltip-rounded: $global-rounded;
//$tooltip-pip-size: rem-calc(8);
//$tooltip-pip-stretch:rem-calc(8);
$tooltip-max-width: rem-calc(265);
//
//$include-tooltip-border : true;
//$tooltip-border-width : rem-calc(1);
//$tooltip-border-style : solid;
//$tooltip-border-color : #d7d7d7;

//$include-tooltip-shadow:true;
//$tooltip-shadow:0px 0px 5px 2px rgba(29, 174, 239, .40);

//$include-hover-tip-shadow:false;
//$tip-hover-shadow:0 0 5px 5px rgba(29, 174, 239, 0.4) inset;
//
// TOP BAR
//

// $include-html-top-bar-classes: $include-html-classes;

// Background color for the top bar
$topbar-bg-color: #ffffff;
// $topbar-bg: $topbar-bg-color;

// Height and margin
$topbar-height: rem-calc(75);
// $topbar-margin-bottom: 0;

// Controlling the styles for the title in the top bar
// $topbar-title-weight: normal;
// $topbar-title-font-size: rem-calc(17);

// Style the top bar dropdown elements
$topbar-dropdown-bg: #ffffff;
$topbar-dropdown-link-color: #446694;
$topbar-dropdown-link-bg: #ffffff;
$topbar-dropdown-link-bg-hover: #ffffff;

// $topbar-dropdown-link-weight: normal;
$topbar-dropdown-toggle-size: 0;
$topbar-dropdown-toggle-color: #446694;
// $topbar-dropdown-toggle-alpha: 0.4;

// Set the link colors and styles for top-level nav
$topbar-link-color: #446694;
$topbar-link-color-hover: #446694;
$topbar-link-padding: rem-calc(20);
// $topbar-link-color-active: #fff;
// $topbar-link-color-active-hover: #fff;
//$topbar-link-weight: bold;
// $topbar-link-font-size: rem-calc(13);
// $topbar-link-hover-lightness: -10%; // Darken by 10%
$topbar-link-bg-hover: #ffffff;
// $topbar-link-bg-active: $primary-color;
// $topbar-link-bg-active-hover: scale-color($primary-color, $lightness: -14%);
// $topbar-link-font-family: $body-font-family;

// $topbar-button-font-size: 0.75rem;

// $topbar-dropdown-label-color: #777;
// $topbar-dropdown-label-text-transform: uppercase;
// $topbar-dropdown-label-font-weight: bold;
// $topbar-dropdown-label-font-size: rem-calc(10);
// $topbar-dropdown-label-bg: #333;

// Top menu icon styles
// $topbar-menu-link-transform: uppercase;
// $topbar-menu-link-font-size: rem-calc(13);
// $topbar-menu-link-weight: bold;
$topbar-menu-link-color: #333;
$topbar-menu-icon-color: #3582b8;
// $topbar-menu-link-color-toggled: #888;
$topbar-menu-icon-color-toggled: #3582b8;

// Transitions and breakpoint styles
// $topbar-transition-speed: 300ms;
// Using rem-calc for the below breakpoint causes issues with top bar
// $topbar-breakpoint: #{upper-bound($medium-range)}; // Change to 9999px for always mobile layout
// $topbar-media-query: "only screen and (min-width: #{upper-bound($medium-range)})";

// Divider Styles
// $topbar-divider-border-bottom: solid 1px  scale-color($topbar-bg-color, $lightness: 13%);
// $topbar-divider-border-top: solid 1px scale-color($topbar-bg-color, $lightness: -50%);

// Sticky Class
// $topbar-sticky-class: ".sticky";
// $topbar-arrows: true; //Set false to remove the triangle icon from the menu item

//
// VISIBILITY CLASSES
//

// $include-html-visibility-classes: $include-html-classes;

// RANGE SLIDER
$include-html-range-slider-classes: $include-html-classes;

// These variabels define the slider bar styles

$range-slider-bar-width: 100%;
$range-slider-bar-height: rem-calc(18);

$range-slider-bar-border-width: 0px;
$range-slider-bar-border-style: solid;
$range-slider-bar-border-color: #94cbe4;
$range-slider-radius: $global-radius;
$range-slider-round: $global-rounded;
$range-slider-bar-bg-color: #c3c1c1;

// Vertical bar styles
$range-slider-vertical-bar-width: rem-calc(16);
$range-slider-vertical-bar-height: rem-calc(200);

// These variabels define the slider handle styles
$range-slider-handle-width: rem-calc(36);
$range-slider-handle-height: rem-calc(36);
$range-slider-handle-position-top: rem-calc(-8.5);
$range-slider-handle-radius: $global-radius;
$range-slider-handle-round: $global-rounded;
$range-slider-handle-cursor: pointer;

$range-slider-handle-bg-color: #659c1a;

$include-range-slider-handle-border: true;
$range-slider-handle-border-width: rem-calc(3);
$range-slider-handle-border-style: solid;
$range-slider-handle-border-color: #fff;

$include-range-slider-handle-outline: false;
$range-slider-handle-outline-width: rem-calc(1);
$range-slider-handle-outline-color: none;
$range-slider-handle-outline-style: solid;

$include-range-slider-handle-shadow: false;
$range-slider-handle-shadow: 0px 0px 5px 2px rgba(29, 174, 239, 0.4);

//Hover
$range-slider-handle-bg-hover-color: $range-slider-handle-bg-color;

$include-range-slider-handle-border-hover: true;
$range-slider-handle-border-hover-width: rem-calc(3);
$range-slider-handle-border-hover-style: solid;
$range-slider-handle-border-hover-color: #fff;

$include-range-slider-handle-outline-hover: false;
$range-slider-handle-outline-hover-width: rem-calc(1);
$range-slider-handle-outline-hover-color: none;
$range-slider-handle-outline-hover-style: solid;

$include-range-slider-handle-shadow-hover: true;
$range-slider-handle-hover-shadow: 0px 0px 5px 2px rgba(38, 125, 168, 0.5);

//Active
$range-slider-handle-bg-active-color: #fff;

$include-range-slider-handle-border-active: true;
$range-slider-handle-border-active-width: rem-calc(10);
$range-slider-handle-border-active-style: solid;
$range-slider-handle-border-active-color: $range-slider-handle-bg-color;

$include-range-slider-handle-outline-active: true;
$range-slider-handle-outline-active-width: rem-calc(3);
$range-slider-handle-outline-active-color: #fff;
$range-slider-handle-outline-active-style: solid;

$include-range-slider-handle-shadow-active: true;
$range-slider-handle-active-shadow: $range-slider-handle-hover-shadow;

$range-slider-active-segment-bg: #8dc63f;

$include-range-slider-icon: false;
//$include-range-slider-icon-content : $fa-var-ellipsis-v $fa-var-ellipsis-v $fa-var-ellipsis-v;
//$include-range-slider-icon-left:rem-calc(14);
//$include-range-slider-icon-top:rem-calc(14);
//$include-range-slider-icon-width:rem-calc(22);
//$include-range-slider-icon-font-size:rem-calc(18);
//$include-range-slider-icon-color:#d4d4d4;
//$include-range-slider-icon-color-hover:#89c8e5;
//$include-range-slider-icon-color-active:#d4d4d4;
//$include-range-slider-icon-letter-space:rem-calc(2);

$range-slider-labels-color: #989898;
$range-slider-labels-font-size: rem-calc(14);

$include-range-slider-value-tip: true;
$range-slider-value-tip-color: #6d787a;
$range-slider-value-tip-font-size: rem-calc(24);
$range-slider-value-tip-width: rem-calc(100);
$range-slider-value-tip-top: -rem-calc(60);

$progress-meter-success-color: #8dc63f !default;
//$progress-bar-height: rem-calc(15);

$balloon-bg-color: rgba(53, 130, 184, 0.9);
$balloon-medium-size: rem-calc(191);
