$reveal-modal-body-padding: rem-calc(15)!default;
$reveal-modal-body-button-margin-top : rem-calc(10)!default;
.reveal-modal {
  &.tiny  {
    @include reveal-modal-base(false, 400px);
  }
  &.small{
    @include reveal-modal-base(false, 574px);
  }
  border: none;
  .modal-header{
    border-radius:$global-radius;
    font-size: 14px;
    padding: 10px;
    background: #fafafa;
  }
  &.error{
    .modal-header{
      background: $alert-color;
      color: #fff;
    }
    .close-reveal-modal{
      color: #fff;
    }
  }
  .body{
    padding: $reveal-modal-body-padding;
    margin-top: $reveal-modal-body-button-margin-top;
    margin-bottom: $reveal-modal-body-button-margin-top;
    .info{
      font-size: 12px;
      line-height: 1.75;
    }
  }
  .close-reveal-modal{
    z-index: 100;
  }
}